import { useMutation, useQuery } from "@apollo/client";
import Check from "@mui/icons-material/Check";
import Clear from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import { useAlerts } from "components/Alerts/AlertProvider";
import { mdHeight, xsHeight } from "components/AppBar";
import { ConfirmDialog } from "components/ConfirmDialog";
import { IssueListButton } from "components/Forms/IssueListButton";
import { tabsOffset } from "components/TabLinks";
import { useThemeMode } from "hooks/useThemeMode";
import { useCallback, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { hasCriticalIssues } from "util/forms";
import {
  COMMUNICATION_QUERY,
  CREATE_COMMUNICATIONS_MUTATION,
  UPDATE_COMMUNICATION_MUTATION,
} from "../api";
import {
  CommunicationFormType,
  transformCommunicationFormDataToInput,
  useCommunicationForm,
  useCommunicationFormContext,
} from "./hooks/useCommunicationForm";
import { DocumentsStep } from "./steps/DocumentsStep";
import { OrganizationStep } from "./steps/OrganizationStep";
import { PreviewStep } from "./steps/PreviewStep";
import { RecipientsStep } from "./steps/RecipientsStep";
import { ReportsStep } from "./steps/ReportsStep";

const CommunicationFormContent = () => {
  const alerts = useAlerts();
  const navigate = useNavigate();
  const { communicationId } = useParams<{ communicationId: string }>();
  const {
    mode,
    handleSubmit,
    issues,
    formState: { isDirty, isSubmitting },
    reset,
  } = useCommunicationFormContext();
  const isView = mode === "view";
  const [step, setStep] = useState<1 | 2>(1);
  const [showConfirmSendAllDialog, setShowConfirmSendAllDialog] =
    useState(false);
  const [createCommunication, { loading: createLoading }] = useMutation(
    CREATE_COMMUNICATIONS_MUTATION
  );
  const [updateCommunication, { loading: updateLoading }] = useMutation(
    UPDATE_COMMUNICATION_MUTATION
  );

  const onSubmit = useCallback(
    async (formData: CommunicationFormType) => {
      const input = transformCommunicationFormDataToInput(formData);

      if (communicationId) {
        const { data } = await updateCommunication({
          variables: {
            id: communicationId,
            input: {
              ...input,
              recipient: input.recipients[0],
            },
            draft: false,
          },
        });
        if (data?.updateCommunication) {
          alerts.success("Communication updated successfully");
          navigate(`/staff/mail-room/status/${communicationId}`);
        }
      } else {
        const { data } = await createCommunication({
          variables: { input },
        });
        if (data?.createCommunication) {
          alerts.success("Communications created successfully");
          navigate(`/staff/mail-room/status`);
        }
      }
    },
    [
      createCommunication,
      updateCommunication,
      alerts,
      navigate,
      communicationId,
    ]
  );

  const handleFormSubmit = useCallback(
    () => handleSubmit((formData) => onSubmit(formData))(),
    [handleSubmit, onSubmit]
  );

  const sendAll = useCallback(() => {
    // show send all confirmation modal. on confirm, submit the form
    setShowConfirmSendAllDialog(true);
  }, []);

  const nextStep = useCallback(() => {
    setStep(2);
  }, []);

  const theme = useTheme();
  const { themeMode } = useThemeMode();

  const issuesButton = isView ? undefined : (
    <IssueListButton issues={issues} issueDescriptor="Validation" />
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          background: blueGrey[themeMode === "light" ? 50 : 900],
          minHeight: {
            xs: `calc(100vh - ${theme.spacing(xsHeight + tabsOffset)})`,
            md: `calc(100vh - ${theme.spacing(mdHeight + tabsOffset)})`,
          },
        }}
      >
        <Box flexGrow={1} p={2}>
          <Card>
            <CardContent>
              <Stack direction="column" gap={3}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h5">Send Mail</Typography>
                  <Typography variant="caption" color="text.secondary">
                    Step {step} of 2
                  </Typography>
                </Box>

                {step === 1 ? (
                  <>
                    <OrganizationStep />
                    <ReportsStep />
                    <DocumentsStep />
                    <RecipientsStep />
                  </>
                ) : (
                  <PreviewStep />
                )}
              </Stack>
            </CardContent>
          </Card>
        </Box>
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            zIndex: 10,
            display: "flex",
            justifyContent: "flex-end",
            py: 2,
            px: 3,
            backgroundColor: theme.palette.background.paper,
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          {!isView && step === 1 && (
            <Box flexGrow={1}>
              <Button
                startIcon={<Clear />}
                onClick={() => reset()}
                disabled={!isDirty}
              >
                Clear
              </Button>
            </Box>
          )}
          {isView && (
            <Box flexGrow={1}>
              <Button variant="outlined" onClick={() => navigate("../send")}>
                Send new mail
              </Button>
            </Box>
          )}
          <Box gap={2} display="flex" alignItems="center">
            <Typography variant="caption" color="text.secondary">
              Step {step} of 2
            </Typography>
            {step === 1 && (
              <>
                {issuesButton}
                <LoadingButton
                  variant="contained"
                  onClick={nextStep}
                  endIcon={<ArrowRightIcon />}
                  disabled={isDirty ? issues.length > 0 : !isView}
                >
                  Next Step
                </LoadingButton>
              </>
            )}
            {step === 2 && (
              <>
                <Button onClick={() => setStep(1)}>Back</Button>
                {issuesButton}

                {isView ? (
                  <Button endIcon={<Check />} color="success">
                    Sent
                  </Button>
                ) : (
                  <LoadingButton
                    variant="contained"
                    disabled={hasCriticalIssues(issues) || isSubmitting}
                    onClick={sendAll}
                  >
                    Send All
                  </LoadingButton>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
      <ConfirmDialog
        loading={isSubmitting}
        open={showConfirmSendAllDialog}
        title="Confirm Send Mail"
        msg={"Are you sure you want to send all mailings?"}
        confirmText="Yes, send all"
        onClose={() => setShowConfirmSendAllDialog(false)}
        onConfirm={handleFormSubmit}
      />
    </>
  );
};

export const CreateCommunications = () => {
  const { communicationId } = useParams<{ communicationId: string }>();
  const { data: communicationData, loading: communicationLoading } = useQuery(
    COMMUNICATION_QUERY,
    {
      variables: { id: communicationId ?? "" },
      skip: !communicationId,
    }
  );

  const methods = useCommunicationForm(communicationData?.communication);
  if (communicationLoading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Skeleton variant="rectangular" height={1000} />
      </Box>
    );
  return (
    <FormProvider {...methods}>
      <CommunicationFormContent />
    </FormProvider>
  );
};
