import { Stack } from "@mui/material";
import { PreviewRecipient } from "../components/PreviewRecipient";
import { useCommunicationFormContext } from "../hooks/useCommunicationForm";

export const PreviewStep = () => {
  const { watch } = useCommunicationFormContext();
  const recipients = watch("recipients");

  return (
    <Stack spacing={3}>
      {recipients.map((_, index) => (
        <PreviewRecipient key={index} index={index} />
      ))}
    </Stack>
  );
};
