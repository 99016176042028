import Upload from "@mui/icons-material/Upload";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
  AddEditDocumentDialog,
  AddEditDocumentDialogState,
  defaultAddEditDocumentDialogState,
  EditMode,
} from "components/AddEditDocumentDialog";
import { ErrorDisplay } from "components/Forms/ErrorDisplay";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { DocumentsDataGrid } from "../components/DocumentsDataGrid";
import { DocumentsPickerDialog } from "../components/DocumentsPickerDialog";
import { useCommunicationFormContext } from "../hooks/useCommunicationForm";

export const DocumentsStep = () => {
  const { control, watch, setValue, mode } = useCommunicationFormContext();
  const disabled = mode === "view";

  const tenant = watch("tenant");
  const facilityIds = watch("facilityIds") || [];

  const [showDocumentPicker, setShowDocumentPicker] = useState(false);
  const [addEditDialogState, setAddEditDialogState] =
    useState<AddEditDocumentDialogState>(defaultAddEditDocumentDialogState);

  return (
    <Stack spacing={3}>
      <Stack spacing={1}>
        <Typography variant="subtitle1">Documents</Typography>
        <Typography variant="caption">
          Select document(s) to mail to all recipients. Choose existing
          documents from any associated facility or report, or upload a new
          file. Cover letters will be attached in the next step, once recipients
          are selected.
        </Typography>
      </Stack>
      <Box>
        <Controller
          name="attachmentDocumentIds"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Stack gap={3}>
              <Stack direction="row" gap={3}>
                <Button
                  size="large"
                  variant="outlined"
                  disabled={disabled || !tenant}
                  onClick={() => setShowDocumentPicker(true)}
                >
                  Select Documents
                </Button>
                <Button
                  variant="text"
                  size="small"
                  startIcon={<Upload />}
                  disabled={disabled || !tenant}
                  onClick={() =>
                    setAddEditDialogState((state) => ({
                      ...state,
                      open: true,
                      mode: EditMode.ADD,
                    }))
                  }
                >
                  Upload New
                </Button>
              </Stack>

              {addEditDialogState.open && (
                <AddEditDocumentDialog
                  dialogState={addEditDialogState}
                  setDialogState={setAddEditDialogState}
                  tenantId={tenant?.id ?? ""}
                  showFacilityPicker
                  showTagPicker
                  onSubmit={(document) => {
                    onChange(value.concat([document.id]));
                    setAddEditDialogState(defaultAddEditDocumentDialogState);
                  }}
                />
              )}

              {showDocumentPicker && (
                <DocumentsPickerDialog
                  tenantId={tenant?.id ?? ""}
                  facilityIds={facilityIds}
                  initialDocumentIds={value}
                  close={() => setShowDocumentPicker(false)}
                  saveDocumentIds={(documentIds) => {
                    onChange(documentIds);
                    setShowDocumentPicker(false);
                  }}
                />
              )}

              {value.length > 0 && (
                <DocumentsDataGrid
                  documentIds={value}
                  removeDocument={
                    disabled
                      ? null
                      : (id) => {
                          onChange(value.filter((x) => x !== id));
                        }
                  }
                />
              )}

              <ErrorDisplay error={error} />
            </Stack>
          )}
        />
      </Box>
    </Stack>
  );
};
