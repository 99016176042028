import { useQuery } from "@apollo/client";
import Remove from "@mui/icons-material/Remove";
import { Tooltip } from "@mui/material";
import { GridColDef, GridSortModel } from "@mui/x-data-grid-premium";
import { DataGrid } from "components/DataGrid";
import { HoverAction } from "components/HoverAction";
import { gql } from "generated-graphql";
import { CommunicationReportsQuery } from "generated-graphql/graphql";
import { usePaginationModel } from "hooks/usePaginationModel";
import { useMemo, useState } from "react";
import { getFacilityName } from "../../utils";

const COMMUNICATION_REPORTS = gql(`
  query CommunicationReports($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    tierIIReports(search:$search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        facility {
          id
          name
          customerFacilityId
        }
        reportingYear
        reportKind
      }
      count
    }
  }
`);

export const ReportsDataGrid = ({
  tierIIReportIds,
  removeReport,
}: {
  tierIIReportIds: string[];
  removeReport: ((id: string) => void) | null;
}) => {
  const pageSize = 10;
  const [paginationModel, setPaginationModel] = usePaginationModel(pageSize);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "facility.name", sort: "asc" },
  ]);

  const { data, previousData, loading } = useQuery(COMMUNICATION_REPORTS, {
    variables: {
      search: `id:${tierIIReportIds.join("|")}`,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sort: sortModel,
    },
  });

  const columns: GridColDef<
    CommunicationReportsQuery["tierIIReports"]["items"][number]
  >[] = useMemo(() => {
    return [
      {
        field: "facility.name",
        headerName: "Facility",
        flex: 1,
        valueGetter: (params) => {
          const facilityName = getFacilityName(params.row.facility);
          return facilityName;
        },
      },
      {
        field: "reportingYear",
        headerName: "Reporting Year",
        flex: 1,
      },
      {
        field: "reportKind",
        headerName: "Type",
        flex: 1,
      },
      {
        field: "actions",
        type: "actions",
        getActions: ({ row }) => {
          const actions = [];

          if (removeReport) {
            actions.push(
              <Tooltip key="remove" title="Remove">
                <HoverAction
                  label="Remove"
                  icon={<Remove fontSize="small" color="primary" />}
                  onClick={() => removeReport(row.id)}
                />
              </Tooltip>
            );
          }
          return actions;
        },
      },
    ];
  }, [removeReport]);

  const items = useMemo(() => {
    return data?.tierIIReports.items ?? previousData?.tierIIReports.items ?? [];
  }, [data?.tierIIReports.items, previousData?.tierIIReports.items]);
  const count = useMemo(() => {
    return data?.tierIIReports.count ?? previousData?.tierIIReports.count ?? 0;
  }, [data?.tierIIReports.count, previousData?.tierIIReports.count]);

  return (
    <DataGrid
      columns={columns}
      rows={items}
      rowCount={count}
      loading={loading}
      isRowSelectable={() => false}
      pagination
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      hideFooter={count <= pageSize}
    />
  );
};
