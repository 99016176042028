import { useLazyQuery } from "@apollo/client";
import AttachFile from "@mui/icons-material/AttachFile";
import Remove from "@mui/icons-material/Remove";
import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { DocumentsForCommunicationQuery } from "generated-graphql/graphql";
import GET_DOCUMENT_DOWNLOAD_LINK_QUERY from "queries/getDocumentDownloadLink";

type GraphQLDocument = NonNullable<
  DocumentsForCommunicationQuery["documents"]["items"]
>[number];

interface DocumentItemProps {
  document: GraphQLDocument;
  onRemove?: () => void;
  removeButtonTitle?: string;
}

export const DocumentItem = ({
  document,
  onRemove,
  removeButtonTitle = "",
}: DocumentItemProps) => {
  const alerts = useAlerts();
  const [getDocumentDownloadLink] = useLazyQuery(
    GET_DOCUMENT_DOWNLOAD_LINK_QUERY,
    {
      variables: { id: document.id },
      onCompleted: (data) => {
        if (!data?.getDocumentDownloadLink) {
          alerts.error("Error fetching download link");
          return;
        }
        window.open(data.getDocumentDownloadLink, "_blank");
      },
      onError: (e) => {
        alerts.error("Error fetching download link", e);
      },
    }
  );

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        minWidth: 0,
        flexShrink: 1,
        overflow: "hidden",
        alignItems: "center",
      }}
    >
      <Tooltip title={`Download ${document.title}`}>
        <Button
          onClick={() => getDocumentDownloadLink()}
          startIcon={<AttachFile />}
          disableRipple
          disableFocusRipple
          sx={{
            color: "primary.main",
            textTransform: "none",
            padding: 0,
            minWidth: 0,
            overflow: "hidden",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <Typography
            variant="body2"
            noWrap
            sx={{ textOverflow: "ellipsis", overflow: "hidden", minWidth: 0 }}
          >
            {document.title}
          </Typography>
        </Button>
      </Tooltip>

      {onRemove && (
        <Tooltip title={removeButtonTitle}>
          <span>
            <IconButton
              size="small"
              title={removeButtonTitle}
              onClick={onRemove}
            >
              <Remove fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </Stack>
  );
};
