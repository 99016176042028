import { useQuery } from "@apollo/client";
import ExpandMore from "@mui/icons-material/ExpandMore";
import OpenInNew from "@mui/icons-material/OpenInNew";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  DialogContent,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Dialog } from "components/Dialog";
import CopyableTypography from "components/Typography/CopyableTypography";
import { JobStatus, StaffJobDialogQuery } from "generated-graphql/graphql";
import { Link } from "react-router-dom";
import { prettyPrintDateTime } from "util/dates";
import { STAFF_JOB_DIALOG_QUERY } from "./api";
import { JobLogTable } from "./JobLogsTable";
import { JobStepsDoneTable } from "./JobStepsDoneTable";
import { getJobStatusColor } from "./utils";
import { JobDownloadButton } from "./JobDownloadButton";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";

export function JobDialog({
  jobId,
  onClose,
}: {
  jobId: string;
  onClose: () => void;
}) {
  const theme = useTheme();
  const { data } = useQuery<StaffJobDialogQuery>(STAFF_JOB_DIALOG_QUERY, {
    variables: { id: jobId },
  });

  return (
    <Dialog
      open={true}
      onClose={() => {
        onClose();
      }}
      maxWidth="lg"
    >
      <DialogContent sx={{ overflow: "unset" }}>
        <Typography variant="h6">Job Details</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="medium">
              Job ID:
            </Typography>
            <CopyableTypography variant="body2" text={data?.job.id} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="medium">
              Status:
            </Typography>
            <Chip
              size="small"
              label={data?.job.status}
              sx={{
                backgroundColor: getJobStatusColor({
                  jobStatus: data?.job.status as JobStatus,
                  theme,
                }).main,
                color: getJobStatusColor({
                  jobStatus: data?.job.status as JobStatus,
                  theme,
                }).contrastText,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" fontWeight="medium">
              Tenant:
            </Typography>
            <Typography variant="body2">
              {data?.job.tenant?.name ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {data?.job.facility ? (
              <>
                <Typography variant="subtitle1" fontWeight="medium">
                  Facility:
                </Typography>
                <Box display="flex" alignItems="center">
                  <Link
                    to={`/o/${data.job.tenant?.id}/facilities/${data.job.facility.id}`}
                    target="_blank"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2" sx={{ marginRight: 1 }}>
                      {data.job.facility.name}
                    </Typography>
                    <OpenInNew fontSize="small" color="primary" />
                  </Link>
                </Box>
              </>
            ) : null}
          </Grid>
          {data?.job.document ? (
            <Grid item xs={6}>
              <Typography variant="subtitle1" fontWeight="medium">
                Document:
              </Typography>
              <Box display="flex" alignItems="center">
                <Link
                  to={`/o/${data.job.tenant?.id}/documents/${data.job.document.id}`}
                  target="_blank"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ marginRight: 1 }}>
                    {data.job.document.title}
                  </Typography>
                  <OpenInNew fontSize="small" color="primary" />
                </Link>
              </Box>
            </Grid>
          ) : null}
          {data?.job.communication ? (
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" fontWeight="medium">
                Communication:
              </Typography>
              <Typography variant="body2">
                <Tooltip title="View in Mail Room">
                  <Link
                    to={`/staff/mail-room/status/${data.job.communication.id}`}
                    target="_blank"
                  >
                    {prettyPrintEnumValue(data.job.communication.status)}
                  </Link>
                </Tooltip>
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="medium">
              Created:
            </Typography>
            <Typography variant="body2"></Typography>
            {prettyPrintDateTime(data?.job.createdAt)}
            <Typography variant="body2">{data?.job.createdBy}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="medium">
              Updated:
            </Typography>
            <Typography variant="body2">
              {prettyPrintDateTime(data?.job.updatedAt)}
            </Typography>
            <Typography variant="body2">{data?.job.updatedBy}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="subtitle1" fontWeight="medium">
                  Parameters
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <pre>{JSON.stringify(data?.job.parameters, null, 2)}</pre>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="subtitle1" fontWeight="medium">
                  Full Job Record
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 1 }}
                >
                  This is the full Job record from the database with null values
                  removed:
                </Typography>
                <pre>
                  {JSON.stringify(
                    Object.fromEntries(
                      Object.entries(data?.job.record ?? {}).filter(
                        ([_, v]) => v != null
                      )
                    ),
                    null,
                    2
                  )}
                </pre>
              </AccordionDetails>
            </Accordion>

            {data?.job.output?.downloadKeys &&
              data?.job.output?.downloadKeys.length > 0 && (
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <JobDownloadButton
                    downloadKeys={data.job.output.downloadKeys}
                  />
                </Grid>
              )}
          </Grid>

          <Grid item xs={12}>
            <JobLogTable jobId={data?.job.id ?? ""} />
          </Grid>
          <Grid item xs={12}>
            <JobStepsDoneTable jobId={data?.job.id ?? ""} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
