import { Button, useMediaQuery, useTheme } from "@mui/material";
import { IssueListTooltip } from "components/IssueListTooltip";
import { Issue } from "generated-graphql/graphql";
import pluralize from "pluralize";
import { useMemo, useState } from "react";

export const IssueListButton = ({
  issues,
  issueDescriptor = "Compliance",
}: {
  issues: Issue[];
  issueDescriptor?: string;
}) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const toggleTooltip = () => {
    setOpen((open) => !open);
  };

  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const label = useMemo(() => {
    const numErrors = issues.length;

    if (isLargeScreen) {
      return `${
        numErrors > 0 ? numErrors : "No"
      } ${issueDescriptor} ${pluralize("Issue", numErrors)}`;
    }
    return "Issues";
  }, [isLargeScreen, issueDescriptor, issues.length]);

  return (
    <IssueListTooltip
      issues={issues}
      open={open}
      onClose={() => setOpen(false)}
    >
      <Button
        type="button"
        color="error"
        onClick={toggleTooltip}
        disabled={!issues?.length}
        variant="outlined"
      >
        {label}
      </Button>
    </IssueListTooltip>
  );
};
