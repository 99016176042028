import { useQuery } from "@apollo/client";
import AlternateEmail from "@mui/icons-material/AlternateEmail";
import Check from "@mui/icons-material/Check";
import MailOutline from "@mui/icons-material/MailOutline";
import PlaceOutlined from "@mui/icons-material/PlaceOutlined";
import Upload from "@mui/icons-material/Upload";
import { Box, Button, Stack, Typography } from "@mui/material";
import { pink } from "@mui/material/colors";
import {
  AddEditDocumentDialog,
  AddEditDocumentDialogState,
  defaultAddEditDocumentDialogState,
  EditMode,
} from "components/AddEditDocumentDialog";
import { ErrorDisplay } from "components/Forms/ErrorDisplay";
import { CommunicationType, DocumentType } from "generated-graphql/graphql";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { formatCommunicationType } from "../../utils";
import { useCommunicationFormContext } from "../hooks/useCommunicationForm";
import { useRecipientForm } from "../hooks/useRecipientForm";
import { DocumentItem } from "./DocumentItem";
import { DOCUMENTS_FOR_COMMUNICATION } from "./DocumentsPickerDialog";
import { COMMUNICATION_FACILITIES } from "./FacilitiesDataGrid";

export const PreviewRecipient = ({ index }: { index: number }) => {
  const { watch, mode, control, issues } = useCommunicationFormContext();
  const { communicationType } = useRecipientForm(index);
  const isView = mode === "view";
  const recipient = watch(`recipients.${index}`);
  const tenant = watch("tenant");
  const documentIds = watch("attachmentDocumentIds");
  const facilityIds = watch("facilityIds");

  const [addEditDialogState, setAddEditDialogState] =
    useState<AddEditDocumentDialogState>(defaultAddEditDocumentDialogState);

  const { data: documents } = useQuery(DOCUMENTS_FOR_COMMUNICATION, {
    variables: { search: `id:${documentIds.join("|")}` },
    skip: !documentIds.length,
  });

  const { data: facilities } = useQuery(COMMUNICATION_FACILITIES, {
    variables: {
      search: `id:${facilityIds.join("|")}`,
    },
    skip: !facilityIds.length,
  });

  const addressLines = [
    recipient.mailRecipientAddressLine1,
    recipient.mailRecipientAddressLine2,
    recipient.mailRecipientCity,
  ]
    .filter(Boolean)
    .join(" ");

  const stateZip = [recipient.mailRecipientState, recipient.mailRecipientZip]
    .filter(Boolean)
    .join(" ");

  const fullAddress = [addressLines, stateZip].filter(Boolean).join(", ");

  const isEmail = communicationType === CommunicationType.Email;

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Stack direction="row" spacing={3} flexGrow={1} minWidth={0}>
        <Box sx={{ p: 1 }}>
          <MailOutline />
        </Box>

        <Stack spacing={1} flexGrow={1} overflow="hidden" minWidth={0}>
          <Typography variant="subtitle1">{recipient.recipientName}</Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {[
              tenant?.name,
              facilities?.facilities.items.map((f) => f.name).join(", "),
            ].join(" | ")}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {formatCommunicationType(communicationType)}
          </Typography>

          {communicationType === CommunicationType.Email && (
            <Stack direction="row" gap={1} alignItems="center">
              <AlternateEmail color="primary" />
              <Typography>{recipient.emailRecipientAddresses}</Typography>
            </Stack>
          )}

          {communicationType !== CommunicationType.Email && (
            <Stack direction="row" gap={1} alignItems="center">
              <PlaceOutlined sx={{ color: pink[300] }} />
              <Typography>{fullAddress}</Typography>
            </Stack>
          )}

          {documents?.documents.items.map((document) => (
            <DocumentItem key={document.id} document={document} />
          ))}

          <Controller
            name={`recipients.${index}.coverLetterDocument`}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Stack spacing={2}>
                <Box display="flex" justifyContent="flex-start">
                  {value && (
                    <>
                      <DocumentItem
                        document={value}
                        onRemove={isView ? undefined : () => onChange(null)}
                        removeButtonTitle="Remove cover letter"
                      />
                      <ErrorDisplay error={error} />
                    </>
                  )}

                  {!value && !isView && (
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<Upload />}
                      disabled={!tenant}
                      onClick={() =>
                        setAddEditDialogState((state) => ({
                          ...state,
                          open: true,
                          mode: EditMode.ADD,
                          documentType: DocumentType.CoverLetter,
                        }))
                      }
                    >
                      Add Cover Letter
                    </Button>
                  )}
                </Box>
                {addEditDialogState.open && (
                  <AddEditDocumentDialog
                    dialogState={addEditDialogState}
                    setDialogState={setAddEditDialogState}
                    tenantId={tenant?.id ?? ""}
                    documentTypeOptions={[DocumentType.CoverLetter]}
                    onSubmit={(document) => {
                      onChange(document);
                      setAddEditDialogState(defaultAddEditDocumentDialogState);
                    }}
                    refetchQueries={[]}
                  />
                )}
              </Stack>
            )}
          />

          <Box>
            {isView && (
              <Button
                endIcon={isView ? <Check /> : null}
                color="success"
                disableRipple
                disableFocusRipple
              >
                Sent via {isEmail ? "email" : "mail"}
              </Button>
            )}
          </Box>
        </Stack>
      </Stack>

      {isView && (
        <Stack textAlign="right">
          <Typography variant="caption">kevin@encamp.com</Typography>
          <Typography variant="caption">
            sent on {new Date().toLocaleString()}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
