import Close from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description";
import HikingIcon from "@mui/icons-material/Hiking";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { Dialog } from "components/Dialog";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { gql } from "generated-graphql";
import { TierIiReportsForCommunicationQuery } from "generated-graphql/graphql";
import { useMemo, useState } from "react";
import { getFacilityName } from "../../utils";

type Row = TierIiReportsForCommunicationQuery["tierIIReports"]["items"][number];

const TIER_II_REPORTS_FOR_COMMUNICATION = gql(`
  query TierIIReportsForCommunication($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    tierIIReports(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        tenantId
        reportKind
        reportingYear
        facilityId
        facility {
          id
          name
          customerFacilityId
        }
      }
      count
    }
  }
`);

interface ReportsGridProps {
  facilityIds: string[];
  initialReportIds: string[];
  close: () => void;
  saveReportIds: (facilityIds: string[]) => void;
}

export const ReportsPickerDialog = ({
  facilityIds,
  initialReportIds,
  close,
  saveReportIds,
}: ReportsGridProps) => {
  const [reportIds, setReportIds] = useState(initialReportIds);

  const columns: GridColDef<Row>[] = useMemo(() => {
    return [
      {
        field: "facility",
        headerName: "Facility",
        flex: 1.5,
        minWidth: 200,
        valueGetter: (params) => {
          const facilityName = getFacilityName(params.row.facility);
          return facilityName;
        },
      },
      {
        field: "reportingYear",
        headerName: "Reporting Year",
        flex: 0.5,
        minWidth: 150,
      },
      {
        field: "reportKind",
        headerName: "Type",
        flex: 0.8,
        minWidth: 120,
        valueGetter: (params) => {
          return params.row.reportKind;
        },
        valueFormatter: (params) => {
          return prettyPrintEnumValue(params.value);
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        flex: 0.6,
        minWidth: 80,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Box>
            <Tooltip title="Go to Fulfillment">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(`/staff/fulfillment/${params.row.id}`, "_blank");
                }}
              >
                <HikingIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="View Customer Report">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(
                    `/o/${params.row.tenantId}/chemicals/reports/${params.row.id}`,
                    "_blank"
                  );
                }}
              >
                <DescriptionIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ];
  }, []);

  // Construct facility filter for the search query
  const facilityFilter = facilityIds.join("|");

  return (
    <Dialog open fullWidth maxWidth="md" onClose={close}>
      <DialogTitle
        justifyContent="space-between"
        display="flex"
        alignItems="center"
      >
        Associate Reports
        <IconButton onClick={close}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Typography variant="subtitle1">
            Select reports that you want this mailing to be associated with. If
            you want the report itself to be included in the mailing, be sure to
            select it in the Documents section.
          </Typography>
          <OmnisearchDataGrid
            columns={columns}
            dataQuery={TIER_II_REPORTS_FOR_COMMUNICATION}
            skip={!facilityFilter}
            initialSortModel={[
              { field: "reportingYear", sort: "desc" },
              { field: "updatedAt", sort: "desc" },
            ]}
            defaultSearch={`facilityId:${facilityFilter}`}
            getItems={(data) => data.tierIIReports.items}
            getCount={(data) => data.tierIIReports.count}
            noDataMessage="No reports found for the selected facilities."
            isURLDriven={false}
            selectedIds={reportIds}
            disableRowSelectionOnClick={false}
            withPadding={false}
            initialPageSize={10}
            onSelectedIdsChanged={(ids) => setReportIds(ids as string[])}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={close}>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => saveReportIds(reportIds)}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};
