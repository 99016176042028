import { useLazyQuery, useQuery } from "@apollo/client";
import Close from "@mui/icons-material/Close";
import Preview from "@mui/icons-material/Preview";
import Remove from "@mui/icons-material/Remove";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridColDef, GridSortModel } from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { DataGrid } from "components/DataGrid";
import { Dialog } from "components/Dialog";
import { DocumentPreview } from "components/DocumentPreview";
import { HoverAction } from "components/HoverAction";
import { gql } from "generated-graphql";
import {
  DocumentsForCommunicationQuery,
  DocumentType,
} from "generated-graphql/graphql";
import { usePaginationModel } from "hooks/usePaginationModel";
import { startCase } from "lodash";
import GET_DOCUMENT_DOWNLOAD_LINK_QUERY from "queries/getDocumentDownloadLink";
import { useMemo, useState } from "react";
import { documentTypeToLabel } from "util/constants";
import { prettyPrintDateMed } from "util/dates";

type Row = DocumentsForCommunicationQuery["documents"]["items"][number];

export const COMMUNICATION_DOCUMENTS = gql(`
  query CommunicationDocuments($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    documents(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        title
        documentType
        storageLink
        fileExtension
        documentTags{
            id
            name
        }
        createdAt
        facilities {
          id
          name
          tierIIReports {
            id
            reportKind
            reportingYear
          }
        }
      }
      count
    }
  }
`);

export const DocumentsDataGrid = ({
  documentIds,
  removeDocument,
}: {
  documentIds: string[];
  removeDocument: ((id: string) => void) | null;
}) => {
  const alerts = useAlerts();
  const pageSize = 10;
  const [paginationModel, setPaginationModel] = usePaginationModel(pageSize);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "createdAt", sort: "desc" },
  ]);

  const [previewFileUrl, setPreviewFileUrl] = useState<string | undefined>(
    undefined
  );
  const closePreview = () => setPreviewFileUrl(undefined);

  const [getDocumentDownloadLink] = useLazyQuery(
    GET_DOCUMENT_DOWNLOAD_LINK_QUERY,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (!data?.getDocumentDownloadLink) {
          return alerts.error("Error fetching download link");
        }
      },
      onError: (e) => {
        alerts.error("Error fetching download link", e);
      },
    }
  );

  const { data, previousData, loading } = useQuery(COMMUNICATION_DOCUMENTS, {
    variables: {
      search: `id:${documentIds.join("|")}`,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sort: sortModel,
    },
  });

  const columns: GridColDef<Row>[] = useMemo(() => {
    return [
      {
        field: "title",
        headerName: "Name",
        flex: 1.5,
        minWidth: 200,
        sortable: true,
        renderCell(params) {
          return (
            <Box
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {params.row.title}
            </Box>
          );
        },
      },
      {
        field: "documentType",
        headerName: "Type",
        flex: 0.8,
        minWidth: 120,
        sortable: true,
        valueGetter(params) {
          return documentTypeToLabel(params.row.documentType);
        },
        filterKeyType: "enum",
        enumValues: Object.values(DocumentType),
        enumPresentationFunction: (enumValue: string) => startCase(enumValue),
      },
      {
        field: "fileExtension",
        headerName: "File Type",
        flex: 0.5,
        minWidth: 80,
        sortable: true,
      },
      {
        field: "facility",
        headerName: "Facility",
        filterKeyType: "facility",
        flex: 1,
        minWidth: 160,
        sortable: false,
        renderCell(params) {
          const facilities = params.row.facilities ?? [];
          return (
            <Stack>
              {facilities.slice(0, 3).map((facility) => (
                <Typography key={facility.id} variant="body2">
                  {facility.name}
                </Typography>
              ))}
              {facilities.length > 3 && (
                <Typography variant="body2">...</Typography>
              )}
            </Stack>
          );
        },
      },
      {
        field: "createdAt",
        headerName: "Uploaded On",
        flex: 0.6,
        minWidth: 120,
        sortable: true,
        valueGetter(params) {
          return prettyPrintDateMed(params.row.createdAt);
        },
      },
      {
        field: "actions",
        type: "actions",
        flex: 0.3,
        minWidth: 80,
        getActions: ({ row }) => {
          const actions = [
            <Tooltip title="Preview Document" key="preview">
              <HoverAction
                onClick={async () => {
                  const { data } = await getDocumentDownloadLink({
                    variables: { id: row.id },
                  });

                  if (
                    data?.getDocumentDownloadLink === null ||
                    data?.getDocumentDownloadLink === undefined
                  ) {
                    alerts.error("Error fetching file url");
                    return;
                  }
                  setPreviewFileUrl(data.getDocumentDownloadLink);
                }}
                label="Preview Document"
                icon={<Preview fontSize="small" color="primary" />}
              />
            </Tooltip>,
          ];

          if (removeDocument) {
            actions.push(
              <Tooltip title="Remove" key="remove">
                <HoverAction
                  label="Remove"
                  icon={<Remove fontSize="small" color="primary" />}
                  onClick={() => removeDocument(row.id)}
                />
              </Tooltip>
            );
          }

          return actions;
        },
      },
    ];
  }, [removeDocument, getDocumentDownloadLink, alerts]);

  const items = useMemo(() => {
    return data?.documents.items ?? previousData?.documents.items ?? [];
  }, [data?.documents.items, previousData?.documents.items]);
  const count = useMemo(() => {
    return data?.documents.count ?? previousData?.documents.count ?? 0;
  }, [data?.documents.count, previousData?.documents.count]);

  return (
    <>
      <DataGrid
        columns={columns}
        rows={items}
        rowCount={count}
        loading={loading}
        isRowSelectable={() => false}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        hideFooter={count <= pageSize}
      />
      {!!previewFileUrl && (
        <Dialog open onClose={closePreview} fullWidth maxWidth="lg">
          <DialogTitle
            justifyContent="space-between"
            display="flex"
            alignItems="center"
          >
            Document Preview
            <IconButton onClick={closePreview}>
              <Close />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <DocumentPreview fileUrl={previewFileUrl} />
          </DialogContent>
          <DialogActions>
            <Button onClick={closePreview}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
