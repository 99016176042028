/**
 * In Tier II reports, a user must report the quantity of different chemicals
 * stored at their facilities. This may be in the form of putting in exact
 * amounts or it may be through the selection of "range codes". For some extra
 * fun, the range codes are _mostly_ the same across states, but there are some
 * exceptions.
 *
 * Source forked from previous application:
 *   https://github.com/Encamp/evergreen/blob/b529a8852054489c33049731d129a2b8a913fc22/_frontend_src/constants/amounts.js
 */

import { TierIIFacilityProduct, TierIIProduct } from "../generated-graphql";
import type { DeepPartial } from "../utils/types";
import { StateAbbreviation } from "./states";

const AMOUNT_CODE_NUMBERS = <const>[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

const OREGON_AMOUNT_CODE_NUMBERS = <const>[
  0, 1, 2, 3, 4, 10, 11, 20, 21, 30, 31, 40, 41, 42, 43, 50, 51, 52, 53, 60, 61,
  70, 71, 80, 81, 90, 91, 99,
];
const NEW_JERSEY_AMOUNT_CODE_NUMBERS = <const>[
  9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
];

const AMOUNT_CODES_MINIMA = (<const>{
  1: 0,
  2: 100,
  3: 500,
  4: 1000,
  5: 5000,
  6: 10000,
  7: 25000,
  8: 50000,
  9: 75000,
  10: 100000,
  11: 500000,
  12: 1000000,
  13: 10000000,
}) as { [key: number]: number };

export const AMOUNT_CODE_FRACTION = 0.0001;

export const AMOUNT_CODES_MAXIMA: { [key: number]: number } = {
  1: 100 - AMOUNT_CODE_FRACTION,
  2: 500 - AMOUNT_CODE_FRACTION,
  3: 1000 - AMOUNT_CODE_FRACTION,
  4: 5000 - AMOUNT_CODE_FRACTION,
  5: 10000 - AMOUNT_CODE_FRACTION,
  6: 25000 - AMOUNT_CODE_FRACTION,
  7: 50000 - AMOUNT_CODE_FRACTION,
  8: 75000 - AMOUNT_CODE_FRACTION,
  9: 100000 - AMOUNT_CODE_FRACTION,
  10: 500000 - AMOUNT_CODE_FRACTION,
  11: 1000000 - AMOUNT_CODE_FRACTION,
  12: 10000000 - AMOUNT_CODE_FRACTION,
  13: Infinity,
};

const OREGON_AMOUNT_CODES_MINIMA: { [key: number]: number } = {
  0: 0,
  1: 5,
  2: 10,
  3: 20,
  4: 50,
  10: 200,
  11: 500,
  20: 1000,
  21: 5000,
  30: 10000,
  31: 50000,
  40: 100000,
  41: 250000,
  42: 500000,
  43: 750000,
  50: 1000000,
  51: 2500000,
  52: 5000000,
  53: 7500000,
  60: 10000000,
  61: 25000000,
  70: 50000000,
  71: 75000000,
  80: 100000000,
  81: 250000000,
  90: 500000000,
  91: 750000000,
  99: 1000000000,
};

export const OREGON_AMOUNT_CODES_MAXIMA: { [key: number]: number } = {
  0: 5 - AMOUNT_CODE_FRACTION,
  1: 10 - AMOUNT_CODE_FRACTION,
  2: 20 - AMOUNT_CODE_FRACTION,
  3: 50 - AMOUNT_CODE_FRACTION,
  4: 200 - AMOUNT_CODE_FRACTION,
  10: 500 - AMOUNT_CODE_FRACTION,
  11: 1000 - AMOUNT_CODE_FRACTION,
  20: 5000 - AMOUNT_CODE_FRACTION,
  21: 10000 - AMOUNT_CODE_FRACTION,
  30: 50000 - AMOUNT_CODE_FRACTION,
  31: 100000 - AMOUNT_CODE_FRACTION,
  40: 250000 - AMOUNT_CODE_FRACTION,
  41: 500000 - AMOUNT_CODE_FRACTION,
  42: 750000 - AMOUNT_CODE_FRACTION,
  43: 1000000 - AMOUNT_CODE_FRACTION,
  50: 2500000 - AMOUNT_CODE_FRACTION,
  51: 5000000 - AMOUNT_CODE_FRACTION,
  52: 7500000 - AMOUNT_CODE_FRACTION,
  53: 10000000 - AMOUNT_CODE_FRACTION,
  60: 25000000 - AMOUNT_CODE_FRACTION,
  61: 50000000 - AMOUNT_CODE_FRACTION,
  70: 75000000 - AMOUNT_CODE_FRACTION,
  71: 100000000 - AMOUNT_CODE_FRACTION,
  80: 250000000 - AMOUNT_CODE_FRACTION,
  81: 500000000 - AMOUNT_CODE_FRACTION,
  90: 750000000 - AMOUNT_CODE_FRACTION,
  91: 1000000000 - AMOUNT_CODE_FRACTION,
  99: Infinity,
};

const NEW_JERSEY_AMOUNT_CODES_MINIMA: { [key: number]: number } = {
  9: 1,
  10: 10,
  11: 100,
  12: 500,
  13: 1000,
  14: 2500,
  15: 5000,
  16: 10000,
  17: 25000,
  18: 50000,
  19: 100000,
  20: 500000,
  21: 1000000,
  22: 10000000,
};

export const NEW_JERSEY_AMOUNT_CODES_MAXIMA: { [key: number]: number } = {
  9: 10 - AMOUNT_CODE_FRACTION,
  10: 100 - AMOUNT_CODE_FRACTION,
  11: 500 - AMOUNT_CODE_FRACTION,
  12: 1000 - AMOUNT_CODE_FRACTION,
  13: 2500 - AMOUNT_CODE_FRACTION,
  14: 5000 - AMOUNT_CODE_FRACTION,
  15: 10000 - AMOUNT_CODE_FRACTION,
  16: 25000 - AMOUNT_CODE_FRACTION,
  17: 50000 - AMOUNT_CODE_FRACTION,
  18: 100000 - AMOUNT_CODE_FRACTION,
  19: 500000 - AMOUNT_CODE_FRACTION,
  20: 1000000 - AMOUNT_CODE_FRACTION,
  21: 10000000 - AMOUNT_CODE_FRACTION,
  22: Infinity,
};

export const AMOUNT_CODES_DISPLAY = (<const>{
  "1": "[01] 0-99",
  "2": "[02] 100-499",
  "3": "[03] 500-999",
  "4": "[04] 1,000-4,999",
  "5": "[05] 5,000-9,999",
  "6": "[06] 10,000-24,999",
  "7": "[07] 25,000-49,999",
  "8": "[08] 50,000-74,999",
  "9": "[09] 75,000-99,999",
  "10": "[10] 100,000-499,999",
  "11": "[11] 500,000-999,999",
  "12": "[12] 1,000,000-9,999,999",
  "13": "[13] 10,000,000+",
}) as { [key: string]: string };

export const AMOUNT_CODES_DROPDOWN_SELECTIONS = Object.keys(
  AMOUNT_CODES_DISPLAY
).map((key) => ({
  id: key,
  label: AMOUNT_CODES_DISPLAY[key],
}));

export const OREGON_AMOUNT_CODES_DISPLAY = (<const>{
  "0": "[00] 0-4",
  "1": "[01] 5-9",
  "2": "[02] 10-19",
  "3": "[03] 20-49",
  "4": "[04] 50-199",
  "10": "[10] 200-499",
  "11": "[11] 500-999",
  "20": "[20] 1,000-4,999",
  "21": "[21] 5,000-9,999",
  "30": "[30] 10,000-49,999",
  "31": "[31] 50,000-99,999",
  "40": "[40] 100,000-249,999",
  "41": "[41] 250,000-499,999",
  "42": "[42] 500,000-749,999",
  "43": "[43] 750,000-999,999",
  "50": "[50] 1,000,000-2,499,999",
  "51": "[51] 2,500,000-4,999,999",
  "52": "[52] 5,000,000-7,499,999",
  "53": "[53] 7,500,000-9,999,999",
  "60": "[60] 10,000,000-24,999,999",
  "61": "[61] 25,000,000-49,999,999",
  "70": "[70] 50,000,000-74,999,999",
  "71": "[71] 75,000,000-99,999,999",
  "80": "[80] 100,000,000-249,999,999",
  "81": "[81] 250,000,000-499,999,999",
  "90": "[90] 500,000,000-749,999,999",
  "91": "[91] 750,000,000-999,999,999",
  "99": "[99] 1,000,000,000-Greater than 1 Billion",
}) as { [key: string]: string };

export const OREGON_CODES_DROPDOWN_SELECTIONS = Object.keys(
  OREGON_AMOUNT_CODES_DISPLAY
).map((key) => ({
  id: key,
  label: OREGON_AMOUNT_CODES_DISPLAY[key],
}));

export const NEW_JERSEY_AMOUNT_CODES_DISPLAY = (<const>{
  "9": "[09] 1-9",
  "10": "[10] 10-99",
  "11": "[11] 100-499",
  "12": "[12] 500-999",
  "13": "[13] 1,000-2,499",
  "14": "[14] 2,500-4,999",
  "15": "[15] 5,000-9,999",
  "16": "[16] 10,000-24,999",
  "17": "[17] 25,000-49,999",
  "18": "[18] 50,000-99,999",
  "19": "[19] 100,000-499,999",
  "20": "[20] 500,000-999,999",
  "21": "[21] 1,000,000-9,999,999",
  "22": "[22] 10 million or greater",
}) as { [key: string]: string };

export const NEW_JERSEY_CODES_DROPDOWN_SELECTIONS = Object.keys(
  NEW_JERSEY_AMOUNT_CODES_DISPLAY
).map((key) => ({
  id: key,
  label: NEW_JERSEY_AMOUNT_CODES_DISPLAY[key],
}));

export type StorageUnits =
  | "pounds"
  | "kilograms"
  | "tons"
  | "metric tons"
  | "gallons"
  | "liters"
  | "barrels"
  | "cubic feet";

export type DensityUnit =
  | "pounds / cubic foot"
  | "pounds / gallon"
  | "pounds / liters"
  | "pounds / barrels"
  | "kilograms / gallons"
  | "kilograms / liters"
  | "kilograms / cubic feet"
  | "kilograms / barrels"
  | "tons / gallons"
  | "tons / liters"
  | "tons / cubic feet"
  | "tons / barrels"
  | "metric tons / gallons"
  | "metric tons / liters"
  | "metric tons / cubic feet"
  | "metric tons / barrels";

export enum TIER_II_UNIT {
  POUNDS = "pounds",
  GALLONS = "gallons",
  CUBIC_FEET = "cubic feet",
  TONS = "tons",
  KILOGRAMS = "kilograms",
  METRIC_TONS = "metric tons",
  LITERS = "liters",
  BARRELS = "barrels",
}

export enum TIER_II_DENSITY_UNIT {
  POUNDS_PER_CUBIC_FOOT = "pounds / cubic foot",
  POUNDS_PER_GALLON = "pounds / gallon",
  POUNDS_PER_LITERS = "pounds / liters",
  POUNDS_PER_BARRELS = "pounds / barrels",
  KILOGRAMS_PER_GALLON = "kilograms / gallons",
  KILOGRAMS_PER_LITERS = "kilograms / liters",
  KILOGRAMS_PER_CUBIC_FOOT = "kilograms / cubic feet",
  KILOGRAMS_PER_BARRELS = "kilograms / barrels",
  TONS_PER_GALLON = "tons / gallons",
  TONS_PER_LITERS = "tons / liters",
  TONS_PER_CUBIC_FOOT = "tons / cubic feet",
  TONS_PER_BARRELS = "tons / barrels",
  METRIC_TONS_PER_GALLON = "metric tons / gallons",
  METRIC_TONS_PER_LITERS = "metric tons / liters",
  METRIC_TONS_PER_CUBIC_FOOT = "metric tons / cubic feet",
  METRIC_TONS_PER_BARRELS = "metric tons / barrels",
}

export function stringToUnit(
  unit: string | undefined | null
): TIER_II_UNIT | DensityUnit {
  if (!unit) {
    return TIER_II_UNIT.POUNDS;
  }

  if (TIER_II_UNIT[unit as keyof typeof TIER_II_UNIT]) {
    return unit as TIER_II_UNIT;
  }

  if (DensityUnits.includes(unit as DensityUnit)) {
    return unit as DensityUnit;
  }

  return TIER_II_UNIT.POUNDS;
}

export const VolumeStorageUnits: StorageUnits[] = [
  "gallons",
  "liters",
  "cubic feet",
  "barrels",
];
export const MassStorageUnits: StorageUnits[] = [
  "pounds",
  "kilograms",
  "tons",
  "metric tons",
];

export const DensityUnits: DensityUnit[] = [
  "pounds / cubic foot",
  "pounds / gallon",
  "pounds / liters",
  "pounds / barrels",
  "kilograms / gallons",
  "kilograms / liters",
  "kilograms / cubic feet",
  "kilograms / barrels",
  "tons / gallons",
  "tons / liters",
  "tons / cubic feet",
  "tons / barrels",
  "metric tons / gallons",
  "metric tons / liters",
  "metric tons / cubic feet",
  "metric tons / barrels",
];

export function getAmountCode(amount: string | number, state: string): string {
  // we want the largest amount code whose minimum is still less than or equal to
  //    our actual amount
  // 1. make a copy of the list so we don't reverse it in-place
  // 2. reverse so that when we do the below filter, the largest one will be first
  // 3. filter so that the only codes that remain are those whose min value is less
  //    than the amount, then take the first (largest) of these

  let code_numbers: Readonly<number[]>, code_minima: { [key: number]: number };
  if (state === "OR") {
    code_numbers = OREGON_AMOUNT_CODE_NUMBERS;
    code_minima = OREGON_AMOUNT_CODES_MINIMA;
  } else if (state === "NJ") {
    code_numbers = NEW_JERSEY_AMOUNT_CODE_NUMBERS;
    code_minima = NEW_JERSEY_AMOUNT_CODES_MINIMA;
  } else {
    code_numbers = AMOUNT_CODE_NUMBERS;
    code_minima = AMOUNT_CODES_MINIMA;
  }

  let parsedAmount = typeof amount === "string" ? parseFloat(amount) : amount;
  if (parsedAmount < 1) {
    parsedAmount = 1;
  }

  return String(
    [...code_numbers]
      .reverse()
      .find((code) => code_minima[code] <= parsedAmount)
  );
}

export function getParsedAmountCodeOrNull(
  amount: string | number,
  state?: string | null
): number | null {
  if (!state) {
    return null;
  } else {
    const code = parseInt(getAmountCode(amount, state));
    return isNaN(code) ? null : code;
  }
}

export const getRangeCodeSelections = (state: string) => {
  switch (state) {
    case "NJ":
      return NEW_JERSEY_CODES_DROPDOWN_SELECTIONS;

    case "OR":
      return OREGON_CODES_DROPDOWN_SELECTIONS;

    default:
      return AMOUNT_CODES_DROPDOWN_SELECTIONS;
  }
};

export const getRangeCodeMinima = (state: string, code: number): number => {
  let amountCodeMinima: number;
  if (state === "NJ") {
    amountCodeMinima = NEW_JERSEY_AMOUNT_CODES_MINIMA[code];
  } else if (state === "OR") {
    amountCodeMinima = OREGON_AMOUNT_CODES_MINIMA[code];
  } else {
    amountCodeMinima = AMOUNT_CODES_MINIMA[code];
  }

  if (amountCodeMinima === undefined) {
    throw new Error(`No min value found for code ${code} in state ${state}`);
  }

  return amountCodeMinima;
};

export const getRangeCodeMaxima = (state: string, code: number): number => {
  let amountCodeMaxima: number;
  if (state === "NJ") {
    amountCodeMaxima = NEW_JERSEY_AMOUNT_CODES_MAXIMA[code];
  } else if (state === "OR") {
    amountCodeMaxima = OREGON_AMOUNT_CODES_MAXIMA[code];
  } else {
    amountCodeMaxima = AMOUNT_CODES_MAXIMA[code];
  }

  if (amountCodeMaxima === undefined) {
    throw new Error(`No max value found for code ${code} in state ${state}`);
  }
  return amountCodeMaxima;
};

export const getAmountCodes = (state: string) => {
  switch (state) {
    case "NJ":
      return NEW_JERSEY_AMOUNT_CODES_DISPLAY;

    case "OR":
      return OREGON_AMOUNT_CODES_DISPLAY;

    default:
      return AMOUNT_CODES_DISPLAY;
  }
};

export const calculateCodeByPercentage = (
  state: string,
  code: number,
  percentage: number
) => {
  const maxima = getRangeCodeMaxima(state, code);

  return getAmountCode(Math.floor(((maxima - 1) * percentage) / 100), state);
};

/**
 * Per Eugene, slack thread here:
 * https://encamp.slack.com/archives/C047VPG176X/p1667932067087729?thread_ts=1667861613.224069&cid=C047VPG176X
 *
 * 3 important cases here, should be covered by code and well tested
 *
 * Conceptually, the when a Facility has a chemical on-site, we measure how much
 * and where they are storing it.
 *
 * Both the storage location and the product itself can sometimes be measured
 * in different units, depending on a set of state-by-state rules.
 *
 * At Encamp in Nov 2022, we don't fully support unit conversion, because we
 * can't guarantee we have the product density (so converting from mass to
 * volume is not possible) and Geppetto doesn't always support unit conversion
 * at the state handler level.
 *
 * Therefore, we have to restrict the unit of measure on a per product, per
 * state basis.
 *
 * ========
 * 1. T2S/E-Plan States  (product and storage location can be different)
 * AK, AL, AR, CO, CT, FL, GA, HI, ID, IA, ME, MS, MT, NC, NH, NM, NY, OH, OK,
 * RI, SC, TN, UT, VT, VA, WY
 *
 * TierIIFacilityProduct.unit:
 *    lbs
 * TierIIFacilityProduct.locations.unit:
 *    lbs, kg, ton, metric ton, gal, liter, bbl
 *
 * 2. Restrictive States (product and storage location have to be the same)
 * AZ, DE, IL, IN, KS, KY, LA, MA, MD, MI, MN, NE, NJ, ND, NV, PA, TX, WA, WI,
 * WV
 *
 * TierIIFacilityProduct.unit:
 *    lbs
 * TierIIFacilityProduct.locations.unit:
 *    lbs or no units/not collected
 *
 * 3. Oddballs (but product and storage location have to be the same)
 * CA, OR, SD
 *
 * TierIIFacilityProduct.unit: Differ by state
 *    California    gal, cubic feet, pounds, tons
 *    Oregon        lb (solids), gal (liquids), gal or cubic feet (gas)
 *    South Dakota  lb, gal
 * TierIIFacilityProduct.locations.unit:
 *    Always set to the same as the product level (not collected in portal)
 */

export function getAllTierIIUnits(): TIER_II_UNIT[] {
  return Object.values(TIER_II_UNIT);
}

function canConvert(p: DeepPartial<TierIIProduct> | undefined | null): boolean {
  return (
    typeof p?.density === "number" &&
    Object.values(TIER_II_DENSITY_UNIT).includes(
      p?.densityUnits as TIER_II_DENSITY_UNIT
    )
  );
}

export function getAllowedUnitsProduct(
  state: StateAbbreviation,
  tierIIProduct?: DeepPartial<TierIIProduct> | null
) {
  if (canConvert(tierIIProduct)) {
    return getAllTierIIUnits();
  }

  switch (state) {
    case "CA":
      return [
        TIER_II_UNIT.GALLONS,
        TIER_II_UNIT.CUBIC_FEET,
        TIER_II_UNIT.POUNDS,
        TIER_II_UNIT.TONS,
      ];
    case "OR":
      if (tierIIProduct?.solid) {
        return [TIER_II_UNIT.POUNDS];
      } else if (tierIIProduct?.liquid) {
        return [TIER_II_UNIT.GALLONS];
      } else if (tierIIProduct?.gas) {
        return [TIER_II_UNIT.GALLONS, TIER_II_UNIT.CUBIC_FEET];
      } else {
        // we need the state of matter to know whats allowed!
        return [];
      }
    case "SD":
      return [TIER_II_UNIT.POUNDS, TIER_II_UNIT.GALLONS];
    default:
      return [TIER_II_UNIT.POUNDS];
  }
}

export function getAllowedUnitsStorage(
  state: StateAbbreviation,
  tierIIFacilityProduct?: DeepPartial<TierIIFacilityProduct>
) {
  const productDensityUnits = tierIIFacilityProduct?.product?.densityUnits;

  switch (state) {
    case "CT":
      return [TIER_II_UNIT.POUNDS];
    // T2S/E-plan state = lbs, kg, ton, metric ton, gal, liter, bbl
    // T2S states allow the storage location unit to be reported
    // in different units of measure than the products themselves
    case "AK":
    case "AL":
    case "AR":
    case "CO":
    case "FL":
    case "GA":
    case "HI":
    case "ID":
    case "IA":
    case "ME":
    case "MS":
    case "MT":
    case "NC":
    case "NH":
    case "NM":
    case "NY":
    case "OH":
    case "OK":
    case "RI":
    case "SC":
    case "TN":
    case "UT":
    case "VT":
    case "VA":
    case "WY":
    case "VI": // virgin islands is T2S state as well?
      return [
        TIER_II_UNIT.POUNDS,
        TIER_II_UNIT.KILOGRAMS,
        TIER_II_UNIT.TONS,
        TIER_II_UNIT.METRIC_TONS,
        TIER_II_UNIT.GALLONS,
        TIER_II_UNIT.LITERS,
        TIER_II_UNIT.BARRELS,
      ];

    // If in ones of these states, they only allow pounds in general, and it
    // has to be the same as the product itself
    case "AZ":
    case "DE":
    case "IL":
    case "IN":
    case "KS":
    case "KY":
    case "LA":
    case "MA":
    case "MD":
    case "MI":
    case "MN":
    case "MO":
    case "NE":
    case "NJ":
    case "ND":
    case "NV":
    case "PA":
    case "TX":
    case "WA":
    case "WI":
    case "WV":
      if (canConvert(tierIIFacilityProduct?.product)) {
        return getAllTierIIUnits();
      }

      return [TIER_II_UNIT.POUNDS];

    // These states have to match the unit of measure on the product itself
    default:
      return getUnitFromTierIIFacilityProduct(tierIIFacilityProduct);
  }
}

export function getProductReportingUnit(
  state: StateAbbreviation,
  tierIIFacilityProduct?: DeepPartial<TierIIFacilityProduct>
): TIER_II_UNIT {
  switch (state) {
    case "CA": {
      const { product } = tierIIFacilityProduct || {};
      if (product?.ehs && product?.pure) {
        return TIER_II_UNIT.POUNDS;
      } else {
        return (
          (tierIIFacilityProduct?.unit as TIER_II_UNIT) ?? TIER_II_UNIT.POUNDS
        );
      }
    }
    case "OR":
      if (tierIIFacilityProduct?.product?.liquid) {
        return TIER_II_UNIT.GALLONS;
      } else if (tierIIFacilityProduct?.product?.gas) {
        return tierIIFacilityProduct?.unit === TIER_II_UNIT.CUBIC_FEET
          ? TIER_II_UNIT.CUBIC_FEET
          : TIER_II_UNIT.GALLONS;
      } else {
        return TIER_II_UNIT.POUNDS;
      }
    case "SD":
      return tierIIFacilityProduct?.unit === TIER_II_UNIT.GALLONS
        ? TIER_II_UNIT.GALLONS
        : TIER_II_UNIT.POUNDS;
    default:
      return TIER_II_UNIT.POUNDS;
  }
}

function getUnitFromTierIIFacilityProduct(
  tierIIFacilityProduct?: DeepPartial<TierIIFacilityProduct>
): TIER_II_UNIT[] {
  if (tierIIFacilityProduct?.unit) {
    return [tierIIFacilityProduct.unit as TIER_II_UNIT];
  }

  return [];
}
