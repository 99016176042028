import Add from "@mui/icons-material/Add";
import { Box, Button, Stack, Typography } from "@mui/material";
import { ErrorDisplay } from "components/Forms/ErrorDisplay";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { ReportsDataGrid } from "../components/ReportsDataGrid";
import { ReportsPickerDialog } from "../components/ReportsPickerDialog";
import { useCommunicationFormContext } from "../hooks/useCommunicationForm";

export const ReportsStep = () => {
  const { control, watch, mode } = useCommunicationFormContext();
  const disabled = mode === "view";

  const tenant = watch("tenant");
  const facilityIds = watch("facilityIds") || [];

  const [showReportPicker, setShowReportPicker] = useState(false);

  return (
    <Stack spacing={3}>
      <Typography variant="subtitle1">Reports</Typography>
      <Controller
        name="tierIIReportIds"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <Box>
              <Button
                variant="text"
                size="small"
                startIcon={<Add />}
                disabled={disabled || !tenant}
                onClick={() => setShowReportPicker(true)}
              >
                Associate Reports
              </Button>
            </Box>

            {showReportPicker && (
              <ReportsPickerDialog
                facilityIds={facilityIds}
                initialReportIds={value}
                close={() => setShowReportPicker(false)}
                saveReportIds={(reportIds) => {
                  onChange(reportIds);
                  setShowReportPicker(false);
                }}
              />
            )}

            {value.length > 0 && (
              <ReportsDataGrid
                tierIIReportIds={value}
                removeReport={
                  disabled
                    ? null
                    : (id) => {
                        onChange(value.filter((x) => x !== id));
                      }
                }
              />
            )}

            <ErrorDisplay error={error} />
          </>
        )}
      />
    </Stack>
  );
};
