import { Box, Button, Stack, Typography } from "@mui/material";
import { ErrorDisplay } from "components/Forms/ErrorDisplay";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { FacilitiesDataGrid } from "../components/FacilitiesDataGrid";
import { FacilitiesPickerDialog } from "../components/FacilitiesPickerDialog";
import { TenantPickerSelect } from "../components/TenantPickerSelect";
import { useCommunicationFormContext } from "../hooks/useCommunicationForm";

export const OrganizationStep = () => {
  const { control, watch, mode } = useCommunicationFormContext();
  const disabled = mode === "view";
  const tenant = watch("tenant");

  const [showFacilityPicker, setShowFacilityPicker] = useState(false);

  return (
    <Stack spacing={3}>
      <Typography variant="subtitle1">Organization & Facilities</Typography>
      <TenantPickerSelect />
      <Controller
        name="facilityIds"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <Box>
              <Button
                variant="outlined"
                size="large"
                disabled={disabled || !tenant}
                onClick={() => setShowFacilityPicker(true)}
              >
                Select Facilities
              </Button>
            </Box>

            {showFacilityPicker && (
              <FacilitiesPickerDialog
                tenantId={tenant?.id ?? ""}
                initialFacilityIds={value}
                close={() => setShowFacilityPicker(false)}
                saveFacilityIds={(facilityIds) => {
                  onChange(facilityIds);
                  setShowFacilityPicker(false);
                }}
              />
            )}

            {value.length > 0 && (
              <FacilitiesDataGrid
                facilityIds={value}
                removeFacility={
                  disabled
                    ? null
                    : (facilityId) => {
                        onChange(value.filter((x) => x !== facilityId));
                      }
                }
              />
            )}

            <ErrorDisplay error={error} />
          </>
        )}
      />
    </Stack>
  );
};
