import {
  Box,
  CssBaseline,
  Theme,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useScreenWidth } from "hooks/useScreenWidth";
import { QueryParamProvider } from "providers/queryParams";
import { useMemo } from "react";
import { Outlet } from "react-router-dom";
import { PrevNextNavigationProvider } from "./components/PrevNextNavigation";
import { useThemeMode } from "./hooks/useThemeMode";
import { BreadcrumbProvider } from "./providers/breadcrumbs";
import { brandColors } from "./util";

export function App() {
  const { themeMode } = useThemeMode();
  const { screenWidth } = useScreenWidth();

  const theme: Theme = useMemo(
    () =>
      createTheme({
        components: {
          // https://github.com/mui/material-ui/issues/13253#issuecomment-752302891
          MuiDialog: {
            styleOverrides: {
              paper: {
                "& > form": {
                  display: "contents",
                },
              },
            },
          },

          MuiDialogActions: {
            styleOverrides: {
              root: ({ theme }) => ({
                paddingTop: theme.spacing(3),
                paddingRight: theme.spacing(3),
                paddingBottom: theme.spacing(2),
                paddingLeft: theme.spacing(3),
              }),
            },
          },

          MuiDialogTitle: {
            styleOverrides: {
              root: ({ theme }) => ({
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(3),
              }),
            },
          },

          MuiButton: {
            styleOverrides: {
              root: {
                whiteSpace: "nowrap", // Prevent text from wrapping in all buttons
              },
            },
          },

          MuiToggleButton: {
            styleOverrides: {
              root: {
                whiteSpace: "nowrap",
              },
            },
          },

          MuiInputLabel: {
            styleOverrides: {
              root: {
                "& .input-tooltip": {
                  pointerEvents: "auto", // Enables pointer events when the tooltip is inside the input
                },
              },
            },
          },

          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                "& a, & a:visited": {
                  color: "#ADD8E6",
                  textDecoration: "none",
                },
              },
            },
          },
        },

        palette: {
          mode: themeMode === "dark" ? "dark" : "light",
          text: {
            primary:
              themeMode !== "dark"
                ? brandColors["encamp-gray"][800]
                : brandColors["encamp-gray"][50],
          },
        },
      }),
    [themeMode]
  );

  return (
    <QueryParamProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <PrevNextNavigationProvider>
          <BreadcrumbProvider>
            <Box sx={screenWidth === "fixed" ? { maxWidth: "2000px" } : {}}>
              <Outlet />
            </Box>
          </BreadcrumbProvider>
        </PrevNextNavigationProvider>
      </ThemeProvider>
    </QueryParamProvider>
  );
}
