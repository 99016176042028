import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { FacilityPicker } from "components/FacilityPicker";
import { FacilityTagsPicker } from "components/FacilityTagsPicker";
import { extractKeyValuePairs } from "encamp-shared/src/omnisearch/util";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { FilterKey } from "hooks/useOmnisearchDatagridSettings";
import { useTenant } from "hooks/useTenant";
import { compact } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { TimeFilter } from "./TimeFilterInput";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { format } from "date-fns";
import { DateTime } from "luxon";

function parseOmnisearch(omnisearch: string): { [key: string]: string[] } {
  const parsed: { [key: string]: string[] } = {};

  const keyValuePairs = extractKeyValuePairs(omnisearch);
  keyValuePairs.forEach(({ key, value }) => {
    parsed[key] = value;
  });

  return parsed;
}

function stringifyOmnisearch(obj: {
  [key: string]: string | string[];
}): string {
  return Object.entries(obj)
    .filter(([, v]) => {
      if (Array.isArray(v)) {
        return v;
      }

      return v.trim().length > 0;
    })
    .map(([k, v]) => {
      if (Array.isArray(v)) {
        return `${k}:${v
          // Quote the value if it includes spaces or commas
          .map((v) => (v.includes(" ") || v.includes(",") ? `"${v}"` : v))
          .join(",")}`;
      }

      // Quote the value if it includes spaces or commas
      return v.includes(" ") || v.includes(",") ? `${k}:"${v}"` : `${k}:${v}`;
    })
    .join(" ");
}

type FilterPopoverProps = {
  isOpen: boolean;
  filterButtonAnchor: HTMLButtonElement | null;
  handleCloseFilter: () => void;
  columnFilterKeys: FilterKey[];
  onFilterKeyClick: (key: string) => void;
  omnisearch: string;
};

export function FilterPopover({
  isOpen,
  filterButtonAnchor,
  handleCloseFilter,
  columnFilterKeys,
  onFilterKeyClick,
  omnisearch,
}: FilterPopoverProps) {
  const theme = useTheme();
  const { tenantId } = useTenant();
  const [inputValues, setInputValues] = useState<{
    [key: string]: string | string[];
  }>(parseOmnisearch(omnisearch ?? ""));

  useEffect(() => {
    setInputValues(parseOmnisearch(omnisearch ?? ""));
  }, [omnisearch]);

  const handleApply = useCallback(() => {
    const newOmnisearch = stringifyOmnisearch(inputValues);
    onFilterKeyClick(newOmnisearch);
    handleCloseFilter();
  }, [handleCloseFilter, inputValues, onFilterKeyClick]);

  return (
    <Popover
      open={isOpen}
      anchorEl={filterButtonAnchor}
      onClose={handleCloseFilter}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box sx={{ p: 2, width: 640 }}>
        <Typography variant="h6" component="h2" sx={{ mb: 1 }}>
          Filters
        </Typography>

        {columnFilterKeys?.map(
          ({
            key,
            header,
            filterKeyType,
            HelpTooltip,
            enumValues,
            enumPresentationFunction,
          }) => {
            let input;

            switch (filterKeyType) {
              case "time":
                input = (
                  <TimeFilter
                    value={
                      Array.isArray(
                        inputValues[key] && inputValues[key].length > 0
                      )
                        ? inputValues[key][0]
                        : ""
                    }
                    id={key}
                    key={key}
                    header={header}
                    onChange={(value) =>
                      setInputValues((iv) => ({ ...iv, [key]: value }))
                    }
                    handleApply={handleApply}
                  />
                );
                break;

              case "enum":
                input = (
                  <FormControl fullWidth>
                    <InputLabel id={`${header}-select`} size="small">
                      {header}
                    </InputLabel>
                    <Select
                      labelId={`${header}-select`}
                      aria-labelledby={`${header}-select`}
                      label={header}
                      size="small"
                      value={inputValues[key] ?? ""}
                      onChange={(e) =>
                        setInputValues((iv) => ({
                          ...iv,
                          [key]: e.target.value,
                        }))
                      }
                    >
                      <MenuItem value={""}>Unselect</MenuItem>
                      {enumValues?.map((val) => (
                        <MenuItem key={val} value={val}>
                          {enumPresentationFunction
                            ? enumPresentationFunction(val)
                            : prettyPrintEnumValue(val)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                );
                break;

              case "boolean":
                input = (
                  <FormControl fullWidth>
                    <InputLabel id={`${header}-select`} size="small">
                      {header}
                    </InputLabel>
                    <Select
                      labelId={`${header}-select`}
                      aria-labelledby={`${header}-select`}
                      label={header}
                      size="small"
                      value={inputValues[key] ?? ""}
                      onChange={(e) =>
                        setInputValues((iv) => ({
                          ...iv,
                          [key]: e.target.value,
                        }))
                      }
                    >
                      <MenuItem value={""}>Unselect</MenuItem>
                      <MenuItem value={"true"}>true</MenuItem>
                      <MenuItem value={"false"}>false</MenuItem>
                    </Select>
                  </FormControl>
                );
                break;

              case "number":
                input = (
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <FormControl fullWidth>
                        <Select
                          labelId={`${header}-operator-select`}
                          aria-labelledby={`${header}-operator-select`}
                          size="small"
                          value={
                            (Array.isArray(inputValues[key]) &&
                              inputValues[key].length > 0 &&
                              inputValues[key][0].startsWith(">")) ||
                            (typeof inputValues[key] === "string" &&
                              (inputValues[key] as string).startsWith(">"))
                              ? ">"
                              : (Array.isArray(inputValues[key]) &&
                                  inputValues[key].length > 0 &&
                                  inputValues[key][0].startsWith("<")) ||
                                (typeof inputValues[key] === "string" &&
                                  (inputValues[key] as string).startsWith("<"))
                              ? "<"
                              : "="
                          }
                          onChange={(e) => {
                            const operator = e.target.value;
                            const value =
                              Array.isArray(inputValues[key]) &&
                              inputValues[key].length > 0
                                ? inputValues[key][0].replace(/[<>=]/g, "")
                                : "";
                            setInputValues((iv) => ({
                              ...iv,
                              [key]: `${operator}${value}`,
                            }));
                          }}
                        >
                          <MenuItem value="=">&#61;</MenuItem>
                          <MenuItem value="&gt;">&gt;</MenuItem>
                          <MenuItem value="&lt;">&lt;</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs>
                      <TextField
                        fullWidth
                        label={header}
                        type="number"
                        value={
                          Array.isArray(inputValues[key]) &&
                          inputValues[key].length > 0
                            ? inputValues[key][0].replace(/[<>=]/g, "")
                            : typeof inputValues[key] === "string"
                            ? (inputValues[key] as string).replace(/[<>=]/g, "")
                            : ""
                        }
                        size="small"
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value === "" || !isNaN(Number(value))) {
                            const operator =
                              (Array.isArray(inputValues[key]) &&
                                inputValues[key].length > 0 &&
                                inputValues[key][0].startsWith(">")) ||
                              (typeof inputValues[key] === "string" &&
                                (inputValues[key] as string).startsWith(">"))
                                ? ">"
                                : (Array.isArray(inputValues[key]) &&
                                    inputValues[key].length > 0 &&
                                    inputValues[key][0].startsWith("<")) ||
                                  (typeof inputValues[key] === "string" &&
                                    (inputValues[key] as string).startsWith(
                                      "<"
                                    ))
                                ? "<"
                                : "";
                            setInputValues((iv) => ({
                              ...iv,
                              [key]: `${operator}${value}`,
                            }));
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleApply();
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                );
                break;

              case "facility":
                input = (
                  <FacilityPicker
                    defaultSearchTerm={`tenantId:${tenantId}`}
                    value={{
                      name:
                        Array.isArray(inputValues[key]) &&
                        inputValues[key].length > 0
                          ? inputValues[key][0]
                          : typeof inputValues[key] === "string"
                          ? (inputValues[key] as string)
                          : "",
                      id: "",
                    }}
                    onChange={(value) =>
                      setInputValues((iv) => ({
                        ...iv,
                        [key]: value?.name ?? "",
                      }))
                    }
                    invalid={false}
                    isTouched={false}
                    isDirty={false}
                    textFieldProps={{ size: "small" }}
                    showErrors={false}
                  />
                );
                break;

              case "facilityTags":
                input = (
                  <FacilityTagsPicker
                    value={
                      Array.isArray(inputValues[key])
                        ? compact((inputValues[key] as Array<string>).sort())
                        : []
                    }
                    onChange={(value) =>
                      setInputValues((iv) => ({
                        ...iv,
                        [key]: value,
                      }))
                    }
                  />
                );
                break;

              case "date":
                const dateStr =
                  Array.isArray(inputValues[key]) && inputValues[key].length > 0
                    ? inputValues[key][0]
                    : typeof inputValues[key] === "string"
                    ? (inputValues[key] as string)
                    : "";
                let dateValues: [DateTime | null, DateTime | null];

                if (dateStr.includes("..")) {
                  const [startDate, endDate] = dateStr.split("..");
                  dateValues = [
                    startDate ? DateTime.fromISO(startDate) : null,
                    endDate ? DateTime.fromISO(endDate) : null,
                  ];
                } else if (dateStr.startsWith(">")) {
                  // After date - set start date only
                  dateValues = [DateTime.fromISO(dateStr.substring(1)), null];
                } else if (dateStr.startsWith("<")) {
                  // Before date - set end date only
                  dateValues = [null, DateTime.fromISO(dateStr.substring(1))];
                } else if (dateStr) {
                  // Single date - set both start and end
                  const date = DateTime.fromISO(dateStr);
                  dateValues = [date, date];
                } else {
                  dateValues = [null, null];
                }

                input = (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <DateRangePicker<DateTime>
                        localeText={{
                          start: `${header} Start`,
                          end: `${header} End`,
                        }}
                        value={dateValues}
                        onChange={(newValue) => {
                          const [startDate, endDate] = newValue;
                          if (!startDate && !endDate) {
                            setInputValues((iv) => ({ ...iv, [key]: "" }));
                            return;
                          }

                          let dateString: string;
                          if (startDate && endDate) {
                            if (
                              startDate.toFormat("yyyy-MM-dd") ===
                              endDate.toFormat("yyyy-MM-dd")
                            ) {
                              // Same date - use single date format
                              dateString = startDate.toFormat("yyyy-MM-dd");
                            } else {
                              // Different dates - use range format
                              dateString = `${startDate.toFormat(
                                "yyyy-MM-dd"
                              )}..${endDate.toFormat("yyyy-MM-dd")}`;
                            }
                          } else if (startDate) {
                            // Only start date - use > operator
                            dateString = `>${startDate.toFormat("yyyy-MM-dd")}`;
                          } else {
                            // Only end date - use < operator
                            dateString = `<${endDate?.toFormat("yyyy-MM-dd")}`;
                          }

                          setInputValues((iv) => ({
                            ...iv,
                            [key]: dateString,
                          }));
                        }}
                      />
                    </Grid>
                  </Grid>
                );
                break;

              default:
                input = (
                  <TextField
                    fullWidth
                    label={header}
                    value={(inputValues[key] as string) ?? ""}
                    size="small"
                    onChange={(e) =>
                      setInputValues((iv) => ({
                        ...iv,
                        [key]: e.target.value,
                      }))
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleApply();
                      }
                    }}
                  />
                );
            }

            return (
              <Grid
                key={key}
                container
                sx={{
                  mb: theme.spacing(2),
                }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={11}>
                  {input}
                </Grid>
                {HelpTooltip && <HelpTooltip />}
              </Grid>
            );
          }
        )}
        <Grid
          container
          justifyContent="flex-end"
          columnSpacing={2}
          sx={{ mt: 2 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCloseFilter}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleApply}>
              Apply
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  );
}
