import Close from "@mui/icons-material/Close";
import OpenInNew from "@mui/icons-material/OpenInNew";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { gql } from "generated-graphql";
import { FacilitiesForCommunicationQuery } from "generated-graphql/graphql";
import { useMemo, useState } from "react";
import { getFacilityName } from "../../utils";

type Row = FacilitiesForCommunicationQuery["facilities"]["items"][number];

const FACILITIES_FOR_COMMUNICATION = gql(`
  query FacilitiesForCommunication($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    facilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        name
        customerFacilityId
        lepc {
            name
        }
        fireDepartment {
            name
        }
        streetAddress1
        city
        state
        zip
      }
      count
    }
  }
`);

interface FacilitiesGridProps {
  tenantId: string;
  initialFacilityIds: string[];
  close: () => void;
  saveFacilityIds: (facilityIds: string[]) => void;
}

export const FacilitiesPickerDialog = ({
  tenantId,
  initialFacilityIds,
  close,
  saveFacilityIds,
}: FacilitiesGridProps) => {
  const [facilityIds, setFacilityIds] = useState(initialFacilityIds);

  const columns: GridColDef<Row>[] = useMemo(() => {
    return [
      {
        field: "name",
        headerName: "Facility",
        flex: 1.5,
        minWidth: 180,
        filterKeyType: "facility",
        valueGetter: (params) => {
          const formattedName = getFacilityName(params.row);
          return formattedName;
        },
      },
      {
        field: "state",
        headerName: "State",
        flex: 0.3,
        minWidth: 80,
      },
      {
        field: "streetAddress1",
        headerName: "Street Address",
        flex: 1.2,
        minWidth: 200,
        valueGetter(params) {
          if (!params.row.streetAddress1) return "";
          const { streetAddress1, city, state, zip } = params.row;
          return [streetAddress1, city, state, zip].filter((s) => s).join(", ");
        },
      },
      {
        field: "lepc",
        headerName: "LEPC",
        flex: 0.8,
        minWidth: 140,
        valueGetter(params) {
          return params.row?.lepc?.name || "";
        },
      },
      {
        field: "fireDepartment",
        headerName: "Fire Department",
        flex: 0.8,
        minWidth: 140,
        valueGetter(params) {
          return params.row?.fireDepartment?.name || "";
        },
      },
      {
        field: "actions",
        headerName: "",
        flex: 0.8,
        minWidth: 140,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Tooltip title="Open Facility">
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                window.open(
                  `/o/${tenantId}/facilities/${params.row.id}/profile`,
                  "_blank"
                );
              }}
            >
              <OpenInNew fontSize="small" />
            </IconButton>
          </Tooltip>
        ),
      },
    ];
  }, [tenantId]);

  return (
    <Dialog open fullWidth maxWidth="md" onClose={close}>
      <DialogTitle
        justifyContent="space-between"
        display="flex"
        alignItems="center"
      >
        Facilities
        <IconButton onClick={close}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Typography variant="subtitle1">
            Select one or more facilities
          </Typography>
          <OmnisearchDataGrid
            columns={columns}
            dataQuery={FACILITIES_FOR_COMMUNICATION}
            skip={!tenantId}
            initialSortModel={[
              { field: "state", sort: "asc" },
              { field: "facility", sort: "asc" },
            ]}
            defaultSearch={`tenantId:${tenantId}`}
            getItems={(data) => data.facilities.items}
            getCount={(data) => data.facilities.count}
            noDataMessage="No facilities found for this organization."
            isURLDriven={false}
            selectedIds={facilityIds}
            disableRowSelectionOnClick={false}
            onSelectedIdsChanged={(newFacilityIds) => {
              setFacilityIds(newFacilityIds as string[]);
            }}
            initialPageSize={10}
            withPadding={false}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={close}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => saveFacilityIds(facilityIds)}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};
