import { Box, Tab, Tabs } from "@mui/material";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

function a11yProps(index: number) {
  return {
    id: `mail-room-tab-${index}`,
    "aria-controls": `mail-room-tabpanel-${index}`,
  };
}

const tabs = [
  {
    label: "Status Checker",
    path: "status",
  },
  {
    label: "Send Mail",
    path: "send",
  },
];

export const Communications = () => {
  useBreadcrumb({ label: "Mail Room", to: "/staff/mail-room/status" });
  const location = useLocation();
  const navigate = useNavigate();

  // Check if we're in details view
  const isDetailsView = location.pathname.includes("/status/");

  // Determine active tab based on current path
  const currentPath = location.pathname.split("/");
  const activeTabPath = isDetailsView
    ? "status"
    : currentPath.pop() || "status";
  const activeTab = tabs.findIndex((tab) => tab.path === activeTabPath);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    navigate(tabs[newValue].path);
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTab !== -1 ? activeTab : 0}
          onChange={handleTabChange}
          aria-label="Mail Room tabs"
        >
          {tabs.map((tab, index) => (
            <Tab key={tab.label} label={tab.label} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>
      <Outlet />
    </Box>
  );
};
