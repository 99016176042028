import { useQuery } from "@apollo/client";
import Remove from "@mui/icons-material/Remove";
import { Tooltip } from "@mui/material";
import { GridColDef, GridSortModel } from "@mui/x-data-grid-premium";
import { DataGrid } from "components/DataGrid";
import { HoverAction } from "components/HoverAction";
import { gql } from "generated-graphql";
import { CommunicationFacilitiesQuery } from "generated-graphql/graphql";
import { usePaginationModel } from "hooks/usePaginationModel";
import { useMemo, useState } from "react";
import { getFacilityName } from "../../utils";

export const COMMUNICATION_FACILITIES = gql(`
  query CommunicationFacilities($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    facilities(search:$search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        name
        customerFacilityId
        lepc {
            name
        }
        fireDepartment {
            name
        }
        streetAddress1
        city
        state
        zip
      }
      count
    }
  }
`);

export const FacilitiesDataGrid = ({
  facilityIds,
  removeFacility,
}: {
  facilityIds: string[];
  removeFacility: ((id: string) => void) | null;
}) => {
  const pageSize = 10;
  const [paginationModel, setPaginationModel] = usePaginationModel(pageSize);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "name", sort: "asc" },
  ]);

  const { data, previousData, loading } = useQuery(COMMUNICATION_FACILITIES, {
    variables: {
      search: `id:${facilityIds.join("|")}`,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sort: sortModel,
    },
  });

  const columns: GridColDef<
    CommunicationFacilitiesQuery["facilities"]["items"][number]
  >[] = useMemo(() => {
    return [
      {
        field: "name",
        headerName: "Facility",
        flex: 1.5,
        minWidth: 180,
        valueGetter: ({ row }) => getFacilityName(row),
      },
      {
        field: "state",
        headerName: "State",
        flex: 0.3,
        minWidth: 80,
      },
      {
        field: "streetAddress1",
        headerName: "Street Address",
        flex: 1.2,
        minWidth: 200,
        valueGetter(params) {
          if (!params.row.streetAddress1) return "";
          const { streetAddress1, city, state, zip } = params.row;
          return [streetAddress1, city, state, zip].filter((s) => s).join(", ");
        },
      },
      {
        field: "lepc",
        headerName: "LEPC",
        flex: 0.8,
        minWidth: 140,
        valueGetter(params) {
          return params.row?.lepc?.name || "";
        },
      },
      {
        field: "fireDepartment",
        headerName: "Fire Department",
        flex: 0.8,
        minWidth: 140,
        valueGetter(params) {
          return params.row?.fireDepartment?.name || "";
        },
      },
      {
        field: "actions",
        type: "actions",
        sortable: false,
        filterable: false,
        minWidth: 80,
        getActions: ({ row }) => {
          const actions = [];

          if (removeFacility) {
            actions.push(
              <Tooltip title="Remove" key="remove">
                <HoverAction
                  label="Remove"
                  icon={<Remove fontSize="small" color="primary" />}
                  onClick={() => removeFacility(row.id)}
                />
              </Tooltip>
            );
          }
          return actions;
        },
      },
    ];
  }, [removeFacility]);

  const items = useMemo(() => {
    return data?.facilities.items ?? previousData?.facilities.items ?? [];
  }, [data?.facilities.items, previousData?.facilities.items]);
  const count = useMemo(() => {
    return data?.facilities.count ?? previousData?.facilities.count ?? 0;
  }, [data?.facilities.count, previousData?.facilities.count]);

  return (
    <DataGrid
      columns={columns}
      rows={items}
      rowCount={count}
      loading={loading}
      isRowSelectable={() => false}
      pagination
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      hideFooter={count <= pageSize}
    />
  );
};
