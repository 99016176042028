import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PlaceIcon from "@mui/icons-material/PlaceOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { FormTextField } from "components/Forms/FormTextField";
import { RadioGroupField } from "components/Forms/RadioGroupField";
import { StatePicker } from "components/StatePicker";
import { CommunicationType } from "generated-graphql/graphql";
import { Controller } from "react-hook-form";
import { RecipientType } from "../../utils";
import { useCommunicationFormContext } from "../hooks/useCommunicationForm";
import { useRecipientForm } from "../hooks/useRecipientForm";
import { RecipientPicker } from "./RecipientPicker";

interface RecipientCardProps {
  index: number;
  onRemove: () => void;
}

const CompactFormControlLabel = styled(FormControlLabel)({
  marginLeft: -8,
  "& .MuiFormControlLabel-label": {
    fontSize: "0.875rem",
  },
  "& .MuiRadio-root": {
    padding: "4px 8px",
  },
});

const CompactRadioGroup = styled(RadioGroup)({
  "& .MuiFormControlLabel-root": {
    marginBottom: 2,
  },
});

export const RecipientCard = ({ index, onRemove }: RecipientCardProps) => {
  const { watch, mode } = useCommunicationFormContext();
  const disabled = mode === "view";
  const {
    formContext,
    recipientType,
    isEmailAllowed,
    isMailAllowed,
    preferUncertified,
    clearRecipientInfo,
  } = useRecipientForm(index);
  const { control } = formContext;
  const communicationType = watch(`recipients.${index}.communicationType`);
  const facilityIds = watch("facilityIds");

  return (
    <Box sx={{ mr: 0.5, ml: 0.5 }}>
      <Grid container spacing={2}>
        <Grid item xs={3.5} md={1.75}>
          <RadioGroupField
            label="Recipient Type"
            name={`recipients.${index}.recipientType`}
            control={control}
            fullWidth={false}
            rules={{ required: "Recipient type is required" }}
            disabled={disabled}
            direction="column"
            onChange={(e) => {
              clearRecipientInfo();
            }}
            radioOptions={[
              {
                label: "LEPC",
                value: RecipientType.LEPC,
              },
              {
                label: "FD",
                value: RecipientType.FIRE_DEPARTMENT,
              },
              {
                label: "PD",
                value: RecipientType.POLICE_DEPARTMENT,
              },
              {
                label: "Lead Agency",
                value: RecipientType.LEAD_AGENCY,
              },
              {
                label: "Custom",
                value: RecipientType.CUSTOM,
              },
            ]}
            sx={{
              "& .MuiFormControlLabel-root": {
                marginLeft: -1,
                marginBottom: 0.25,
              },
              "& .MuiRadio-root": {
                padding: "4px 8px",
              },
              "& .MuiFormControlLabel-label": {
                fontSize: "0.875rem",
              },
            }}
          />
        </Grid>
        <Grid item xs={8.5} md={3}>
          <RecipientPicker
            recipientType={recipientType}
            facilityIds={facilityIds}
            control={control}
            disabled={disabled}
            index={index}
          />
        </Grid>

        {/* Middle Column - Communication Type */}
        <Grid item xs={3} md={1.75}>
          <Controller
            name={`recipients.${index}.communicationType`}
            control={control}
            render={({ field }) => {
              const noContactAllowed = !isEmailAllowed && !isMailAllowed;
              const enableEmail = isEmailAllowed || noContactAllowed;
              const enableMail = isMailAllowed || noContactAllowed;

              return (
                <FormControl component="fieldset" size="small" fullWidth>
                  <FormLabel>Mail Type</FormLabel>
                  <CompactRadioGroup {...field}>
                    <Stack spacing={0.25}>
                      <CompactFormControlLabel
                        value={CommunicationType.CertifiedMail}
                        control={
                          <Radio
                            disabled={
                              disabled || !enableMail || preferUncertified
                            }
                            size="small"
                          />
                        }
                        label="Certified Mail"
                      />
                      <CompactFormControlLabel
                        value={CommunicationType.FirstClassMail}
                        control={
                          <Radio
                            disabled={disabled || !enableMail}
                            size="small"
                          />
                        }
                        label="Uncertified Mail"
                      />
                      <CompactFormControlLabel
                        value={CommunicationType.Email}
                        control={
                          <Radio
                            disabled={disabled || !enableEmail}
                            size="small"
                          />
                        }
                        label="Email"
                      />
                    </Stack>
                  </CompactRadioGroup>
                  {!isEmailAllowed && !isMailAllowed && (
                    <Typography color="error" variant="caption">
                      This recipient does not accept any form of communication,
                      pick one at your discretion.
                    </Typography>
                  )}
                </FormControl>
              );
            }}
          />
        </Grid>

        <Grid item xs={0.5} md={0.5} sx={{ mt: 1.5 }}>
          {communicationType === CommunicationType.Email ? (
            <AlternateEmailIcon sx={{ color: "#64B5F6" }} />
          ) : (
            <PlaceIcon sx={{ color: "#F06292" }} />
          )}
        </Grid>

        {/* Right Column - Contact Information */}
        <Grid item xs={8} md={4.5}>
          <Grid container spacing={2}>
            {communicationType === CommunicationType.Email ? (
              <>
                <Grid item xs={12}>
                  <FormTextField
                    label="Email"
                    name={`recipients.${index}.emailRecipientAddresses`}
                    disabled={disabled}
                    control={control}
                    hideErrors={true}
                    textFieldProps={{ required: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTextField
                    name={`recipients.${index}.emailReplyTo`}
                    disabled={disabled}
                    control={control}
                    label="Reply To"
                    hideErrors={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTextField
                    name={`recipients.${index}.emailSubject`}
                    disabled={disabled}
                    control={control}
                    label="Subject"
                    hideErrors={true}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <FormTextField
                    name={`recipients.${index}.mailRecipientAddressLine1`}
                    control={control}
                    label="Street Address"
                    disabled={disabled}
                    hideErrors={true}
                    textFieldProps={{ required: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    name={`recipients.${index}.mailRecipientAddressLine2`}
                    control={control}
                    label="Street Address 2"
                    disabled={disabled}
                    hideErrors={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    name={`recipients.${index}.mailRecipientCity`}
                    control={control}
                    label="City"
                    disabled={disabled}
                    hideErrors={true}
                    textFieldProps={{ required: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StatePicker
                    name={`recipients.${index}.mailRecipientState`}
                    // @ts-ignore TODO: figure out why this type is not compatible
                    control={control}
                    label="State"
                    disabled={disabled}
                    hideErrors={true}
                    textFieldProps={{ required: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name={`recipients.${index}.mailRecipientZip`}
                    control={control}
                    label="Zip Code"
                    disabled={disabled}
                    hideErrors={true}
                    textFieldProps={{ required: true }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={0.5} md={0.5}>
          {!disabled && (
            <IconButton onClick={onRemove} sx={{ ml: -1 }}>
              <RemoveIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
