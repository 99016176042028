import { useEffect, useCallback } from "react";
import { CommunicationType } from "generated-graphql/graphql";
import {
  DEFAULT_EMAIL_FROM,
  RecipientFormType,
  useCommunicationFormContext,
} from "./useCommunicationForm";
import {
  getLepcInfo,
  getFireDepartmentInfo,
  getPoliceDepartmentInfo,
  getLeadAgencyInfo,
  getCommunicationTypeRestrictions,
} from "../utils/agencyInfo";

interface RecipientUpdates extends Partial<RecipientFormType> {
  recipientName?: string;
  mailRecipientAddressLine1?: string;
  mailRecipientAddressLine2?: string;
  mailRecipientCity?: string;
  mailRecipientState?: string;
  mailRecipientZip?: string;
  emailRecipientAddresses?: string[];
  emailSubject?: string;
  emailFrom?: string;
  emailReplyTo?: string;
}

type RecipientField =
  | `recipients.${number}.recipientName`
  | `recipients.${number}.communicationType`
  | `recipients.${number}.lepc`
  | `recipients.${number}.fireDepartment`
  | `recipients.${number}.policeDepartment`
  | `recipients.${number}.leadAgency`
  | `recipients.${number}.mailRecipientAddressLine1`
  | `recipients.${number}.mailRecipientAddressLine2`
  | `recipients.${number}.mailRecipientCity`
  | `recipients.${number}.mailRecipientState`
  | `recipients.${number}.mailRecipientZip`
  | `recipients.${number}.emailRecipientAddresses`
  | `recipients.${number}.emailSubject`
  | `recipients.${number}.emailFrom`
  | `recipients.${number}.emailReplyTo`
  | `recipients.${number}.coverLetterDocument`;

export const useRecipientForm = (index: number) => {
  const formContext = useCommunicationFormContext();
  const { mode } = formContext;

  // Watch specific fields instead of the entire recipient object
  const recipientType = formContext.watch(`recipients.${index}.recipientType`);
  const currentRecipientName = formContext.watch(
    `recipients.${index}.recipientName`
  );
  const lepc = formContext.watch(`recipients.${index}.lepc`);
  const fireDepartment = formContext.watch(
    `recipients.${index}.fireDepartment`
  );
  const policeDepartment = formContext.watch(
    `recipients.${index}.policeDepartment`
  );
  const leadAgency = formContext.watch(`recipients.${index}.leadAgency`);
  const communicationType = formContext.watch(
    `recipients.${index}.communicationType`
  );

  const { isEmailAllowed, isMailAllowed, preferUncertified } =
    getCommunicationTypeRestrictions(recipientType, lepc, fireDepartment);

  const clearRecipientInfo = useCallback(() => {
    const fieldsToClear: RecipientField[] = [
      `recipients.${index}.recipientName`,
      `recipients.${index}.lepc`,
      `recipients.${index}.fireDepartment`,
      `recipients.${index}.policeDepartment`,
      `recipients.${index}.leadAgency`,
      `recipients.${index}.communicationType`,
      `recipients.${index}.mailRecipientAddressLine1`,
      `recipients.${index}.mailRecipientAddressLine2`,
      `recipients.${index}.mailRecipientCity`,
      `recipients.${index}.mailRecipientState`,
      `recipients.${index}.mailRecipientZip`,
      `recipients.${index}.emailRecipientAddresses`,
      `recipients.${index}.emailSubject`,
      `recipients.${index}.emailFrom`,
      `recipients.${index}.emailReplyTo`,
      `recipients.${index}.coverLetterDocument`,
    ];

    fieldsToClear.forEach((field) => {
      formContext.resetField(field);
    });
  }, [formContext, index]);

  useEffect(() => {
    // Only run if we have a recipient type and one of the agency fields is set
    if (!recipientType || mode === "view") {
      return;
    }

    // only run if agency changed or communication type changed

    // Get agency info based on which field changed
    let agencyInfo = null;
    if (lepc) {
      agencyInfo = getLepcInfo(lepc);
    } else if (fireDepartment) {
      agencyInfo = getFireDepartmentInfo(fireDepartment);
    } else if (policeDepartment) {
      agencyInfo = getPoliceDepartmentInfo(policeDepartment);
    } else if (leadAgency) {
      agencyInfo = getLeadAgencyInfo(leadAgency);
    }

    // Create updates object with all recipient fields
    const updates: RecipientUpdates = {};
    let recipientNameToUse = currentRecipientName;
    if (agencyInfo) {
      updates.recipientName = agencyInfo?.contactName ?? agencyInfo?.name;
      recipientNameToUse = updates.recipientName;
    }

    // Handle email communication
    if (communicationType === CommunicationType.Email) {
      updates.mailRecipientAddressLine1 = "";
      updates.mailRecipientAddressLine2 = "";
      updates.mailRecipientCity = "";
      updates.mailRecipientState = "";
      updates.mailRecipientZip = "";
      updates.emailFrom = DEFAULT_EMAIL_FROM;
      updates.emailReplyTo = DEFAULT_EMAIL_FROM;
      if (agencyInfo?.email) {
        updates.emailRecipientAddresses = [agencyInfo.email];
      }
      if (recipientNameToUse) {
        updates.emailSubject = `Tier II - Attn: ${recipientNameToUse}`;
      }
    }

    // Handle mail communication
    if (
      communicationType === CommunicationType.FirstClassMail ||
      communicationType === CommunicationType.CertifiedMail
    ) {
      updates.emailRecipientAddresses = [];
      updates.emailSubject = "";
      updates.emailFrom = "";
      updates.emailReplyTo = "";
      if (agencyInfo?.mailingAddress) {
        updates.mailRecipientAddressLine1 = agencyInfo.mailingAddress;
      }
      if (agencyInfo?.mailingAddressLine2) {
        updates.mailRecipientAddressLine2 = agencyInfo.mailingAddressLine2;
      }
      if (agencyInfo?.city) {
        updates.mailRecipientCity = agencyInfo.city;
      }
      if (agencyInfo?.state) {
        updates.mailRecipientState = agencyInfo.state;
      }
      if (agencyInfo?.zip) {
        updates.mailRecipientZip = agencyInfo.zip;
      }
    }

    // Update only the changed fields
    Object.entries(updates).forEach(([key, value]) => {
      // only update if the value has changed
      const fieldPath = `recipients.${index}.${key}` as RecipientField;
      formContext.setValue(fieldPath, value, {
        shouldValidate: false,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
  }, [
    recipientType,
    lepc?.id,
    fireDepartment?.id,
    policeDepartment?.id,
    leadAgency?.id,
    communicationType,
    formContext.setValue,
    index,
  ]);

  return {
    formContext,
    recipientType,
    lepc,
    fireDepartment,
    policeDepartment,
    leadAgency,
    communicationType,
    isEmailAllowed,
    isMailAllowed,
    preferUncertified,
    clearRecipientInfo,
  };
};
