import { styled } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";

export const HoverAction = styled(GridActionsCellItem)(({ theme }) => ({
  opacity: 0,
  transition: "opacity 0.3s ease",
  ".MuiDataGrid-row:hover &": {
    opacity: 1,
  },
}));
