import { Stack, Typography, useTheme, Tooltip } from "@mui/material";
import Unpublished from "@mui/icons-material/Unpublished";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import {
  CommunicationsQuery,
  CommunicationsQueryVariables,
  CommunicationStatus,
  CommunicationType,
  JobStatus,
} from "generated-graphql/graphql";
import { useSelectedIdInUrl } from "hooks/useSelectedIdInUrl";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { useCallback, useMemo } from "react";
import { prettyPrintDateTime } from "util/dates";
import { COMMUNICATIONS_QUERY } from "./api";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { PaletteColor } from "@mui/material/styles";
import {
  RecipientType,
  getFacilityName,
  formatAddress,
  formatCommunicationType,
} from "./utils";
import OpenInNew from "@mui/icons-material/OpenInNew";
import RedoIcon from "@mui/icons-material/Redo";
import Work from "@mui/icons-material/Work";
import { useLazyQuery, useMutation } from "@apollo/client";
import GET_DOCUMENT_DOWNLOAD_LINK_QUERY from "queries/getDocumentDownloadLink";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  EXEMPT_MAILING_ADDRESS_FROM_VERIFICATION_MUTATION,
  REQUEUE_COMMUNICATION_MUTATION,
} from "./api";
import { useAlerts } from "components/Alerts/AlertProvider";
import { getJobStatusColor } from "../../Jobs/utils";

type CommunicationRow = CommunicationsQuery["communications"]["items"][number];

const communicationStatusToLabel = (
  communicationStatus: CommunicationStatus
) => {
  switch (communicationStatus) {
    case CommunicationStatus.Error:
      return "Error";
    case CommunicationStatus.Pending:
      return "Pending";
    case CommunicationStatus.SentToEmailClient:
      return "Emailed";
    case CommunicationStatus.SentToMailClient:
      return "Mailed";
    case CommunicationStatus.Draft:
      return "Draft";
    default:
      return prettyPrintEnumValue(communicationStatus);
  }
};

export const CommunicationsTable = () => {
  const alerts = useAlerts();
  const theme = useTheme();
  const [getDocumentDownloadLink] = useLazyQuery(
    GET_DOCUMENT_DOWNLOAD_LINK_QUERY
  );

  const {
    selectedId: communicationId,
    pushIdToUrl,
    popIdFromUrl,
  } = useSelectedIdInUrl("communicationId");

  const getCommunicationStatusColorCallback = useCallback(
    ({
      communicationStatus,
      jobStatus,
    }: {
      communicationStatus: CommunicationStatus | undefined | null;
      jobStatus: JobStatus | undefined | null;
    }) => {
      switch (communicationStatus) {
        case CommunicationStatus.SentToEmailClient:
        case CommunicationStatus.SentToMailClient:
          return getJobStatusColor({
            jobStatus,
            theme,
          });
        case CommunicationStatus.Error:
          return theme.palette.error;
        case CommunicationStatus.Draft:
        case CommunicationStatus.Pending:
        default:
          return theme.palette.info;
      }
    },
    [theme]
  );

  const handleViewProof = useCallback(
    async (proofDocumentId: string) => {
      const { data } = await getDocumentDownloadLink({
        variables: { id: proofDocumentId },
      });
      if (data?.getDocumentDownloadLink) {
        window.open(data.getDocumentDownloadLink, "_blank");
      }
    },
    [getDocumentDownloadLink]
  );

  const [exemptMailingAddressFromVerification, { loading }] = useMutation(
    EXEMPT_MAILING_ADDRESS_FROM_VERIFICATION_MUTATION
  );
  const onClickExemptMailingAddressFromVerification = useCallback(
    async (params: CommunicationRow) => {
      if (
        !params.recipientName ||
        !params.mailRecipientAddressLine1 ||
        !params.mailRecipientCity ||
        !params.mailRecipientState ||
        !params.mailRecipientZip
      ) {
        return;
      }
      await exemptMailingAddressFromVerification({
        variables: {
          input: {
            recipientName: params.recipientName,
            primaryLine: params.mailRecipientAddressLine1,
            secondaryLine: params.mailRecipientAddressLine2,
            city: params.mailRecipientCity,
            state: params.mailRecipientState,
            zipCode: params.mailRecipientZip,
          },
        },
      });
      alerts.success("Mailing address exempted from verification");
    },
    [exemptMailingAddressFromVerification, alerts]
  );
  const [requeueCommunication, { loading: requeueLoading }] = useMutation(
    REQUEUE_COMMUNICATION_MUTATION
  );

  const onClickRequeueCommunication = useCallback(
    async (params: CommunicationRow) => {
      if (params.proofDocument) {
        alerts.error("Cannot requeue a communication with a proof document");
        return;
      }
      if (params.status === CommunicationStatus.Draft) {
        alerts.error("Cannot requeue a draft communication");
        return;
      }
      await requeueCommunication({ variables: { id: params.id } });
      alerts.success("Communication requeued");
    },
    [requeueCommunication, alerts]
  );

  const getStatusText = useCallback(
    (
      communicationStatus: CommunicationStatus | undefined,
      jobStatus: JobStatus | undefined | null,
      jobStepError: string | undefined | null,
      lobMailStatus: string | undefined | null
    ): { shortText: string; longText?: string } => {
      switch (communicationStatus) {
        case CommunicationStatus.Error:
          // render the job error message
          if (jobStepError?.toLowerCase().includes("undeliverable")) {
            return {
              shortText: "Error: Undeliverable",
              longText: jobStepError,
            };
          }
          return {
            shortText: `Error: ${jobStepError || "Unknown error"}`,
            longText: jobStepError ?? undefined,
          };
        case CommunicationStatus.Pending:
          return {
            shortText: "Pending",
            longText: undefined,
          };
        case CommunicationStatus.SentToEmailClient:
          if (jobStatus === JobStatus.Failed) {
            return {
              shortText: `Emailed with error: ${
                jobStepError || "Unknown error"
              }`,
              longText: jobStepError ?? undefined,
            };
          }
          return {
            shortText: "Emailed",
            longText: undefined,
          };
        case CommunicationStatus.SentToMailClient:
          if (lobMailStatus) {
            return {
              shortText: `Mailed (${prettyPrintEnumValue(lobMailStatus)})`,
              longText: undefined,
            };
          }
          if (jobStatus === JobStatus.Failed) {
            return {
              shortText: `Mailed with error: ${
                jobStepError || "Unknown error"
              }`,
              longText: jobStepError ?? undefined,
            };
          }
          return {
            shortText: `Mailed (${prettyPrintEnumValue(jobStatus)})`,
            longText: undefined,
          };
        default:
          return {
            shortText: prettyPrintEnumValue(communicationStatus),
            longText: undefined,
          };
      }
    },
    []
  );

  const columns: OmnisearchGridColDef<CommunicationRow>[] = useMemo(
    () => [
      {
        headerName: "Organization",
        field: "tenant",
        flex: 1,
        valueGetter(params) {
          return params.row.tenant.name;
        },
      },
      {
        headerName: "Facility/Facilities",
        field: "facilities",
        flex: 1.2,
        valueGetter(params) {
          const facilities = params.row.facilities.map((f) =>
            getFacilityName(f)
          );
          if (facilities.length <= 2) {
            return facilities.join(", ");
          }
          return `${facilities[0]}, ${facilities[1]} + ${
            facilities.length - 2
          } more`;
        },
      },
      {
        headerName: "Recipient Type",
        field: "recipientType",
        flex: 1,
        enumValues: Object.values(RecipientType),
        filterKeyType: "enum",
        valueGetter(params) {
          if (params.row.lepcId) {
            return RecipientType.LEPC;
          }
          if (params.row.fireDepartmentId) {
            return RecipientType.FIRE_DEPARTMENT;
          }
          if (params.row.policeDepartmentId) {
            return RecipientType.POLICE_DEPARTMENT;
          }
          if (params.row.leadAgencyId) {
            return RecipientType.LEAD_AGENCY;
          }
          return RecipientType.CUSTOM;
        },
        valueFormatter(params) {
          switch (params.value) {
            case RecipientType.LEPC:
              return "LEPC";
            case RecipientType.FIRE_DEPARTMENT:
              return "Fire Department";
            case RecipientType.POLICE_DEPARTMENT:
              return "Police Department";
            case RecipientType.LEAD_AGENCY:
              return "Lead Agency";
            case RecipientType.CUSTOM:
              return "Custom";
            default:
              return prettyPrintEnumValue(params.value);
          }
        },
      },
      {
        headerName: "Recipient",
        field: "recipientName",
        flex: 1,
      },
      {
        headerName: "Method",
        field: "communicationType",
        flex: 1,
        enumValues: Object.values(CommunicationType),
        filterKeyType: "enum",
        valueGetter(params) {
          return formatCommunicationType(params.row?.communicationType);
        },
      },
      {
        headerName: "Address",
        field: "address",
        flex: 1.2,
        valueGetter(params) {
          if (params.row.communicationType === CommunicationType.Email) {
            return params.row.emailRecipientAddresses.join(", ");
          }
          return formatAddress(params.row);
        },
      },
      {
        headerName: "Document(s)",
        field: "attachments",
        flex: 1.2,
        valueGetter(params) {
          return params.row.attachments.map((a) => a.title).join(", ");
        },
        renderCell({ value }: { value?: string }) {
          if (!value) return null;
          const documents = value.split(", ");
          if (documents.length <= 2) {
            return (
              <Tooltip title={value}>
                <Typography variant="body2" noWrap>
                  {value}
                </Typography>
              </Tooltip>
            );
          }

          return (
            <Tooltip title={value}>
              <Typography variant="body2" noWrap>
                {`${documents.slice(0, 2).join(", ")} + ${
                  documents.length - 2
                } more`}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        headerName: "Completed By",
        field: "completedByUser",
        flex: 1,
        valueGetter(params) {
          if (params.row.completedByUser?.email) {
            return params.row.completedByUser.email;
          }
          if (params.row.updatedBy === "workflow-manager") {
            return "Workflow Manager";
          }
          return params.row.updatedBy;
        },
      },
      {
        headerName: "Sent At",
        field: "completedAt",
        flex: 1,
        filterKeyType: "date",
        valueGetter(params) {
          if (!params.row.completedAt) return null;
          return prettyPrintDateTime(params.row.completedAt);
        },
      },
      {
        headerName: "Status",
        field: "status",
        flex: 1,
        enumValues: Object.values(CommunicationStatus),
        filterKeyType: "enum",
        enumPresentationFunction: (enumValue) =>
          communicationStatusToLabel(enumValue as CommunicationStatus),
        renderCell({
          row,
          value,
        }: {
          row: CommunicationRow;
          value?: CommunicationStatus;
        }) {
          const jobStatus = row.job?.status;
          const jobStepError = row.job?.stepError;
          const color: PaletteColor = getCommunicationStatusColorCallback({
            communicationStatus: value,
            jobStatus,
          });
          const { shortText, longText } = getStatusText(
            value,
            jobStatus,
            jobStepError,
            row.lobMail?.status
          );
          return (
            <Tooltip
              title={longText}
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: "1rem",
                    maxWidth: "500px",
                  },
                },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: color.main,
                  maxWidth: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {shortText}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        headerName: "Created At",
        field: "createdAt",
        flex: 1,
        filterKeyType: "date",
        filterable: false,
        valueGetter(params) {
          if (!params.row.createdAt) return null;
          return prettyPrintDateTime(params.row.createdAt);
        },
      },
      {
        headerName: "Actions",
        field: "actions",
        flex: 0.7,
        sortable: false,
        filterable: false,
        type: "actions",
        getActions: (params) => {
          const actions = [
            <GridActionsCellItem
              key="view-proof"
              icon={<OpenInNew />}
              label="View Proof"
              disabled={!params.row.proofDocument}
              showInMenu
              onClick={(e) => {
                e.stopPropagation();
                if (!params.row.proofDocument) return;
                handleViewProof(params.row.proofDocument.id);
              }}
            />,
            <GridActionsCellItem
              key="go-to-job"
              icon={<Work />}
              label="Go to Job"
              disabled={!params.row.job}
              showInMenu
              onClick={(e) => {
                e.stopPropagation();
                window.open(`/staff/jobs/${params.row.job?.id}`, "_blank");
              }}
            />,
            <GridActionsCellItem
              key="exempt-mailing-address"
              icon={<Unpublished />}
              label="Exempt Mailing Address"
              showInMenu
              disabled={
                params.row.status !== CommunicationStatus.Error ||
                loading ||
                (params.row.communicationType !==
                  CommunicationType.CertifiedMail &&
                  params.row.communicationType !==
                    CommunicationType.FirstClassMail)
              }
              onClick={(e) => {
                e.stopPropagation();
                onClickExemptMailingAddressFromVerification(params.row);
              }}
            />,
            <GridActionsCellItem
              key="resend"
              icon={<RedoIcon />}
              label="Resend"
              showInMenu
              disabled={
                requeueLoading ||
                params.row.status === CommunicationStatus.Draft ||
                !!params.row.proofDocument
              }
              onClick={(e) => {
                e.stopPropagation();
                onClickRequeueCommunication(params.row);
              }}
            />,
          ];

          return actions;
        },
      },
    ],
    [
      getCommunicationStatusColorCallback,
      handleViewProof,
      exemptMailingAddressFromVerification,
      getStatusText,
      onClickExemptMailingAddressFromVerification,
      loading,
    ]
  );

  return (
    <Stack py={3}>
      <OmnisearchDataGrid<
        CommunicationsQuery,
        CommunicationsQueryVariables,
        CommunicationRow
      >
        persistenceKey="communications"
        rowHeight="auto"
        initialSortModel={[{ field: "createdAt", sort: "desc" }]}
        onRowClick={(params) => {
          pushIdToUrl(params.row.id);
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              createdAt: false,
            },
          },
        }}
        columns={columns}
        dataQuery={COMMUNICATIONS_QUERY}
        getItems={(data) => data.communications.items}
        getCount={(data) => data.communications.count}
      />
    </Stack>
  );
};
