import Add from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import { Box, Stack, Typography } from "@mui/material";
import { useCallback } from "react";
import { RecipientCard } from "../components/RecipientCard";
import {
  defaultRecipientForm,
  useCommunicationFormContext,
} from "../hooks/useCommunicationForm";

export const RecipientsStep = () => {
  const { watch, setValue, mode } = useCommunicationFormContext();
  const recipients = watch("recipients");
  const disabled = mode === "view";

  const handleAddRecipient = useCallback(() => {
    setValue("recipients", [...recipients, defaultRecipientForm]);
  }, [recipients, setValue]);

  const handleRemoveRecipient = useCallback(
    (index: number) => {
      if (recipients.length === 1) {
        setValue("recipients", [defaultRecipientForm]);
      } else {
        const newRecipients = recipients.map((recipient) => ({ ...recipient }));
        newRecipients.splice(index, 1);
        setValue("recipients", newRecipients);
      }
    },
    [recipients, setValue]
  );

  return (
    <Stack spacing={3}>
      <Typography variant="subtitle1">Recipient(s)</Typography>
      <Stack spacing={3}>
        {recipients.map((recipient, index) => (
          <RecipientCard
            key={index}
            index={index}
            onRemove={() => handleRemoveRecipient(index)}
          />
        ))}
      </Stack>

      {!disabled && (
        <Box>
          <LoadingButton
            onClick={handleAddRecipient}
            disabled={disabled}
            size="small"
            color="primary"
            startIcon={<Add />}
          >
            Add Recipient
          </LoadingButton>
        </Box>
      )}
    </Stack>
  );
};
