import { useLazyQuery } from "@apollo/client";
import Close from "@mui/icons-material/Close";
import Preview from "@mui/icons-material/Preview";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { DocumentPreview } from "components/DocumentPreview";
import { HoverAction } from "components/HoverAction";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { gql } from "generated-graphql";
import { DocumentsForCommunicationQuery } from "generated-graphql/graphql";
import { startCase } from "lodash";
import GET_DOCUMENT_DOWNLOAD_LINK_QUERY from "queries/getDocumentDownloadLink";
import { useMemo, useState } from "react";
import { documentTypeToLabel } from "util/constants";
import { prettyPrintDateMed } from "util/dates";

type Row = DocumentsForCommunicationQuery["documents"]["items"][number];

export const DOCUMENTS_FOR_COMMUNICATION = gql(`
  query DocumentsForCommunication($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    documents(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        title
        documentType
        storageLink
        fileExtension
        documentTags{
            id
            name
        }
        createdAt
        facilities {
          id
          name
          tierIIReports {
            id
            reportKind
            reportingYear
          }
        }
      }
      count
    }
  }
`);

export const DocumentsPickerDialog = ({
  tenantId,
  facilityIds,
  initialDocumentIds,
  close,
  saveDocumentIds,
}: {
  tenantId: string;
  facilityIds: string[];
  initialDocumentIds: string[];
  close: () => void;
  saveDocumentIds: (x: string[]) => void;
}) => {
  const alerts = useAlerts();
  const [documentIds, setDocumentIds] = useState(initialDocumentIds);

  const facilityFilter = useMemo(
    () => (facilityIds.length ? `facilityId:${facilityIds.join("|")}` : ""),
    [facilityIds]
  );
  const [previewFileUrl, setPreviewFileUrl] = useState<string | undefined>(
    undefined
  );
  const closePreview = () => setPreviewFileUrl(undefined);

  const [getDocumentDownloadLink] = useLazyQuery(
    GET_DOCUMENT_DOWNLOAD_LINK_QUERY,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (!data?.getDocumentDownloadLink) {
          return alerts.error("Error fetching download link");
        }
      },
      onError: (e) => {
        alerts.error("Error fetching download link", e);
      },
    }
  );

  const columns: GridColDef<Row>[] = useMemo(() => {
    return [
      {
        field: "title",
        headerName: "Name",
        flex: 1.5,
        minWidth: 200,
        sortable: true,
        renderCell(params) {
          return (
            <Box
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {params.row.title}
            </Box>
          );
        },
      },
      {
        field: "documentType",
        headerName: "Type",
        flex: 0.8,
        minWidth: 120,
        sortable: true,
        valueGetter(params) {
          return documentTypeToLabel(params.row.documentType);
        },
        filterKeyType: "enum",
        enumValues: Object.values(DocumentType),
        enumPresentationFunction: (enumValue: string) => startCase(enumValue),
      },
      {
        field: "fileExtension",
        headerName: "File Type",
        flex: 0.5,
        minWidth: 80,
        sortable: true,
      },
      {
        field: "facility",
        headerName: "Facility",
        filterKeyType: "facility",
        flex: 1,
        minWidth: 160,
        sortable: false,
        renderCell(params) {
          const facilities = params.row.facilities ?? [];
          return (
            <Stack>
              {facilities.slice(0, 3).map((facility) => (
                <Typography key={facility.id} variant="body2">
                  {facility.name}
                </Typography>
              ))}
              {facilities.length > 3 && (
                <Typography variant="body2">...</Typography>
              )}
            </Stack>
          );
        },
      },
      {
        field: "createdAt",
        headerName: "Uploaded On",
        flex: 0.6,
        minWidth: 120,
        sortable: true,
        valueGetter(params) {
          return prettyPrintDateMed(params.row.createdAt);
        },
      },
      {
        field: "actions",
        type: "actions",
        flex: 0.3,
        minWidth: 80,
        getActions: ({ row }) => [
          <Tooltip title="Preview Document" key="preview">
            <HoverAction
              onClick={async () => {
                const { data } = await getDocumentDownloadLink({
                  variables: { id: row.id },
                });

                if (
                  data?.getDocumentDownloadLink === null ||
                  data?.getDocumentDownloadLink === undefined
                ) {
                  alerts.error("Error fetching file url");
                  return;
                }
                setPreviewFileUrl(data.getDocumentDownloadLink);
              }}
              label="Preview Document"
              icon={<Preview fontSize="small" color="primary" />}
            />
          </Tooltip>,
        ],
      },
    ];
  }, [alerts, getDocumentDownloadLink]);

  return (
    <>
      <Dialog open fullWidth maxWidth="md" onClose={close}>
        <DialogTitle
          justifyContent="space-between"
          display="flex"
          alignItems="center"
        >
          Select Documents
          <IconButton onClick={close}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <Typography variant="subtitle1">
              Select existing documents from any associated facility and/or
              report
            </Typography>
            <OmnisearchDataGrid
              columns={columns}
              dataQuery={DOCUMENTS_FOR_COMMUNICATION}
              skip={!tenantId}
              initialSortModel={[{ field: "createdAt", sort: "desc" }]}
              defaultSearch={`tenantId:${tenantId} ${facilityFilter}`.trim()}
              getItems={(data) => data.documents.items}
              getCount={(data) => data.documents.count ?? 0}
              noDataMessage="No documents found for this organization."
              isURLDriven={false}
              selectedIds={documentIds}
              disableRowSelectionOnClick={false}
              onSelectedIdsChanged={(newDocumentIds) => {
                setDocumentIds(newDocumentIds as string[]);
              }}
              initialPageSize={10}
              withPadding={false}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={close}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => saveDocumentIds(documentIds)}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>{" "}
      {!!previewFileUrl && (
        <Dialog open onClose={closePreview} fullWidth maxWidth="lg">
          <DialogTitle
            justifyContent="space-between"
            display="flex"
            alignItems="center"
          >
            Document Preview
            <IconButton onClick={closePreview}>
              <Close />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <DocumentPreview fileUrl={previewFileUrl} />
          </DialogContent>
          <DialogActions>
            <Button onClick={closePreview}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
