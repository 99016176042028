import { Controller, Control } from "react-hook-form";
import { FormControl, Typography } from "@mui/material";
import { LepcPickerWithFacilityFilter } from "components/LepcPicker";
import { FireDepartmentPickerWithFacilityFilter } from "components/FireDepartmentPicker";
import { PoliceDepartmentPicker } from "components/PoliceDepartmentPicker";
import { LeadAgencyPicker } from "components/LeadAgencyPicker";
import { RecipientType } from "../../utils";
import { FormTextField } from "components/Forms/FormTextField";

interface RecipientPickerProps {
  recipientType: RecipientType | undefined;
  facilityIds: string[];
  control: Control<any>;
  disabled?: boolean;
  index: number;
}

export const RecipientPicker = ({
  recipientType,
  facilityIds,
  control,
  disabled = false,
  index,
}: RecipientPickerProps) => {
  switch (recipientType) {
    case RecipientType.LEPC:
      return (
        <Controller
          name={`recipients.${index}.lepc`}
          control={control}
          rules={{ required: "LEPC selection is required" }}
          render={({ field, fieldState }) => (
            <FormControl fullWidth error={!!fieldState.error}>
              <LepcPickerWithFacilityFilter
                facilityIds={facilityIds}
                {...fieldState}
                value={field.value}
                onChange={field.onChange}
                disabled={disabled}
              />
              <Typography color="error" variant="caption">
                {fieldState.error?.message}
              </Typography>
            </FormControl>
          )}
        />
      );
    case RecipientType.FIRE_DEPARTMENT:
      return (
        <Controller
          name={`recipients.${index}.fireDepartment`}
          control={control}
          rules={{ required: "Fire Department selection is required" }}
          render={({ field, fieldState }) => (
            <FormControl fullWidth error={!!fieldState.error}>
              <FireDepartmentPickerWithFacilityFilter
                facilityIds={facilityIds}
                value={field.value}
                {...fieldState}
                onChange={field.onChange}
                disabled={disabled}
              />
              <Typography color="error" variant="caption">
                {fieldState.error?.message}
              </Typography>
            </FormControl>
          )}
        />
      );
    case RecipientType.POLICE_DEPARTMENT:
      return (
        <Controller
          name={`recipients.${index}.policeDepartment`}
          control={control}
          rules={{ required: "Police Department selection is required" }}
          render={({ field, fieldState }) => (
            <FormControl fullWidth error={!!fieldState.error}>
              <PoliceDepartmentPicker
                value={field.value}
                {...fieldState}
                onChange={field.onChange}
                disabled={disabled}
              />
              <Typography color="error" variant="caption">
                {fieldState.error?.message}
              </Typography>
            </FormControl>
          )}
        />
      );
    case RecipientType.LEAD_AGENCY:
      return (
        <Controller
          name={`recipients.${index}.leadAgency`}
          control={control}
          rules={{ required: "Lead Agency selection is required" }}
          render={({ field, fieldState }) => (
            <FormControl fullWidth error={!!fieldState.error}>
              <LeadAgencyPicker
                value={field.value}
                {...fieldState}
                onChange={field.onChange}
                disabled={disabled}
              />
              <Typography color="error" variant="caption">
                {fieldState.error?.message}
              </Typography>
            </FormControl>
          )}
        />
      );
    case RecipientType.CUSTOM:
    default:
      return (
        <FormTextField
          name={`recipients.${index}.recipientName`}
          control={control}
          label="Recipient"
          disabled={disabled}
        />
      );
  }
};
