import { useFormContext } from "react-hook-form";
import { useValidatingForm } from "hooks/useValidatingForm";
import { useLazyQuery } from "@apollo/client";
import {
  LeadAgencyPickerFragment,
  PoliceDepartmentPickerFragment,
  LepcPickerFragment,
  CreateCommunicationInput,
  CommunicationQuery,
  FireDepartmentPickerFragment,
  DocumentsForCommunicationQuery,
  CommunicationStatus,
  DocumentType,
} from "generated-graphql/graphql";
import { RecipientType } from "../../utils";
import { useCallback, useMemo } from "react";
import { COMMUNICATION_VALIDATOR_QUERY } from "../../api";
import { type UseValidatingFormReturn } from "hooks/useValidatingForm";

type GraphQLDocument = NonNullable<
  DocumentsForCommunicationQuery["documents"]["items"]
>[number];

export type RecipientFormType = Omit<
  CreateCommunicationInput["recipients"][number],
  "id" | "createdAt" | "updatedAt" | "coverLetterDocumentId"
> & {
  recipientType?: RecipientType;
  lepc?: LepcPickerFragment | null;
  fireDepartment?: FireDepartmentPickerFragment | null;
  policeDepartment?: PoliceDepartmentPickerFragment | null;
  leadAgency?: LeadAgencyPickerFragment | null;
  coverLetterDocument: GraphQLDocument | null;
  rowId: string;
};

export type CommunicationFormType = Omit<
  CreateCommunicationInput,
  "recipients" | "tenantId"
> & {
  id: string | null;
  status: CommunicationStatus;
  tenant: NonNullable<CommunicationQuery["communication"]>["tenant"] | null;
  recipients: RecipientFormType[];
};
export const DEFAULT_EMAIL_FROM = "no-reply@encamp.com";
export const defaultRecipientForm: RecipientFormType = {
  rowId: crypto.randomUUID(),
  lepcId: null,
  lepc: null,
  coverLetterDocument: null,
  fireDepartmentId: null,
  fireDepartment: null,
  policeDepartmentId: null,
  policeDepartment: null,
  leadAgencyId: null,
  leadAgency: null,
  recipientType: RecipientType.CUSTOM,
  recipientName: "",
  communicationType: null,
  emailRecipientAddresses: [],
  emailSubject: "",
  emailFrom: DEFAULT_EMAIL_FROM,
  emailReplyTo: DEFAULT_EMAIL_FROM,
  emailTemplateId: "",
  mailRecipientAddressLine1: "",
  mailRecipientAddressLine2: "",
  mailRecipientCity: "",
  mailRecipientState: "",
  mailRecipientZip: "",
};

function getRecipientType(communication: CommunicationQuery["communication"]) {
  if (communication?.lepcId) {
    return RecipientType.LEPC;
  }
  if (communication?.fireDepartmentId) {
    return RecipientType.FIRE_DEPARTMENT;
  }
  if (communication?.policeDepartmentId) {
    return RecipientType.POLICE_DEPARTMENT;
  }
  if (communication?.leadAgencyId) {
    return RecipientType.LEAD_AGENCY;
  }
  return RecipientType.CUSTOM;
}

const formDefaults: CommunicationFormType = {
  id: null,
  tenant: null,
  facilityIds: [],
  tierIIReportIds: [],
  status: CommunicationStatus.Pending,
  attachmentDocumentIds: [],
  recipients: [defaultRecipientForm],
};

export const useCommunicationForm = (
  existingCommunication?: CommunicationQuery["communication"]
) => {
  const defaultValues = useMemo<CommunicationFormType>(() => {
    if (existingCommunication) {
      return {
        id: existingCommunication.id,
        status: existingCommunication.status,
        tenant: existingCommunication.tenant,
        facilityIds: existingCommunication.facilities.map(
          (facility) => facility.id
        ),
        tierIIReportIds: existingCommunication.tierIIReports.map(
          (report) => report.id
        ),
        attachmentDocumentIds: existingCommunication.attachments
          .filter((d) => d.documentType !== DocumentType.CoverLetter)
          .map((attachment) => attachment.id),
        recipients: [
          {
            rowId: crypto.randomUUID(),
            coverLetterDocument:
              existingCommunication.attachments.find(
                (d) => d.documentType === DocumentType.CoverLetter
              ) ?? null,
            recipientType: getRecipientType(existingCommunication),
            lepc: existingCommunication.lepc ?? null,
            lepcId: existingCommunication.lepcId,
            fireDepartment: existingCommunication.fireDepartment ?? null,
            fireDepartmentId: existingCommunication.fireDepartmentId,
            policeDepartment: existingCommunication.policeDepartment ?? null,
            policeDepartmentId: existingCommunication.policeDepartmentId,
            leadAgency: existingCommunication.leadAgency ?? null,
            leadAgencyId: existingCommunication.leadAgencyId,
            recipientName: existingCommunication.recipientName,
            communicationType: existingCommunication.communicationType,
            emailRecipientAddresses:
              existingCommunication.emailRecipientAddresses,
            emailSubject: existingCommunication.emailSubject,
            emailFrom: existingCommunication.emailFrom,
            emailReplyTo: existingCommunication.emailReplyTo,
            emailTemplateId: existingCommunication.emailTemplateId,
            mailRecipientAddressLine1:
              existingCommunication.mailRecipientAddressLine1,
            mailRecipientAddressLine2:
              existingCommunication.mailRecipientAddressLine2,
            mailRecipientCity: existingCommunication.mailRecipientCity,
            mailRecipientState: existingCommunication.mailRecipientState,
            mailRecipientZip: existingCommunication.mailRecipientZip,
          },
        ],
      };
    }
    return formDefaults;
  }, [existingCommunication]);

  const methods = useValidatingForm<CommunicationFormType>(
    defaultValues,
    [],
    useCommunicationValidator()
  );

  return methods;
};

const getMode = (
  communicationId: string | null,
  isDraft: boolean
): "create" | "edit" | "view" => {
  if (communicationId) {
    if (isDraft) {
      return "edit";
    } else {
      return "view";
    }
  }
  return "create";
};

export const useCommunicationFormContext = () => {
  const form =
    useFormContext<CommunicationFormType>() as UseValidatingFormReturn<CommunicationFormType>;
  const { watch } = form;

  const isDraft = useMemo(
    () => watch("status") === CommunicationStatus.Draft,
    [watch]
  );
  const mode = useMemo(() => getMode(watch("id"), isDraft), [watch, isDraft]);

  return {
    ...form,
    isDraft,
    mode,
  };
};

// transform form data to the input type
export const transformCommunicationFormDataToInput = (
  data: CommunicationFormType
): CreateCommunicationInput => {
  const { id, status, recipients, tenant, ...rest } = data;

  return {
    ...rest,
    tenantId: tenant?.id ?? "",
    recipients: recipients.map((recipient) => {
      const {
        recipientType,
        lepc,
        fireDepartment,
        policeDepartment,
        leadAgency,
        coverLetterDocument,
        rowId,
        ...recipientInput
      } = recipient;

      return {
        ...recipientInput,
        lepcId: lepc?.id,
        fireDepartmentId: fireDepartment?.id,
        policeDepartmentId: policeDepartment?.id,
        leadAgencyId: leadAgency?.id,
        coverLetterDocumentId: coverLetterDocument?.id,
      };
    }),
  };
};

export const useCommunicationValidator = () => {
  const [query] = useLazyQuery(COMMUNICATION_VALIDATOR_QUERY, {});

  return useCallback(
    async (formData: CommunicationFormType) => {
      const input = transformCommunicationFormDataToInput(formData);
      const { data: result, previousData } = await query({
        variables: { input },
      });
      const validationResults = result
        ? [...result.communicationValidator]
        : [...(previousData?.communicationValidator ?? [])];
      return validationResults;
    },
    [query]
  );
};
