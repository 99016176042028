import { useQuery } from "@apollo/client";
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import { gql } from "generated-graphql";
import { GetTenantsQuery } from "generated-graphql/graphql";
import { useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { useDebounce } from "use-debounce";
import { useCommunicationFormContext } from "../hooks/useCommunicationForm";

const GET_TENANTS = gql(`
  query GetTenants(
    $search: String
    $page: Int
    $pageSize: Int
    $sort: [SortModel!]
  ) {
    tenants(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        name
      }
      count
    }
  }
`);

export const TenantPickerSelect = () => {
  const { control, mode, setValue } = useCommunicationFormContext();
  const disabled = mode === "view";

  const [inputValue, setInputValue] = useState("");
  const [debouncedSearch] = useDebounce(inputValue, 150);
  const variables = useMemo(() => {
    return {
      search: debouncedSearch,
      pageSize: 20,
      sort: [{ field: "name", sort: "asc" }],
    };
  }, [debouncedSearch]);
  const { data, loading } = useQuery(GET_TENANTS, { variables });

  return (
    <Controller
      name="tenant"
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <Box sx={{ width: 296 }}>
          <Autocomplete<GetTenantsQuery["tenants"]["items"][number]>
            options={data?.tenants.items ?? []}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(a, b) => a?.id === b?.id}
            value={value}
            onChange={(_, newVal) => {
              onChange(newVal);
              if (value?.id !== newVal?.id) {
                setValue("facilityIds", []);
              }
            }}
            disabled={disabled}
            loading={loading}
            onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!error}
                label="Organization"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>
      )}
    />
  );
};
